import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { GetDiscountedItemsService } from './getDiscountedItems.service';
import { RestService } from './rest.service';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from './enrollSession.service';
import { AuthService } from './auth.service';
import { SharedMethodService } from './sharedMethod.service';
import { LinkTracking } from 'app/constants';

@Injectable({
    providedIn: 'root'
})
export class CalculateRetailOrderService {
    itemDetail: any = [];
    ArrayList: any = [];
    ParentItemCode: any;
    getProfessionalItems: any;
    discitem: any;
    disCountItem: any;
    priceType: any;
    cartSubtotal: any;
    isLogin: boolean = false;
    customerDetail: any = '';
    DiscountOption:any=[];
    balanceFieldPoint:number = 0;
    public selectedDiscount: any;
    public DiscountValue: any;
    isSelected: boolean = false;
    public totalRemainingBalance: any;
    SmartOrderLength: any[];
    SmartOrderShippingMethod: any;
    
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        private _getDiscountedItemsService: GetDiscountedItemsService,
        private _enrollSession: EnrollSessionService,
        private _auth: AuthService
    ) {
        this._enrollSession.data = JSON.parse(localStorage.getItem('EnrollSession')) || {};
    }
    calculate(items: any, ShipMethodId: any, callback: any, type: any): any {
        
        if (this._auth.isLoggedIn()) {
            this.isLogin = true;
            this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        }

        this.itemDetail = [];
        this.ArrayList = [];
        this.ParentItemCode = '';
        this.priceType = this._enrollSession.data.priceType || 2;
        this.cartSubtotal = 0;
        this.getProfessionalItems = false;
        this.discitem = undefined;
        this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
        this._enrollSession.data.RetailOrderSummary.AddOnOrders = this._enrollSession.data.RetailOrderSummary.AddOnOrders || [];
        var ConcatArray = this._enrollSession.data.RetailOrderSummary.InitialOrders.concat(this._enrollSession.data.RetailOrderSummary.Enrollpack);
        if (type === 4) {
            this.ArrayList = items;
            this.SmartOrderShippingMethod = this._enrollSession.data.ShippingMethods?.find(ShippingMethod =>
                (ShippingMethod.ShipMethodID == 87 || ShippingMethod.ShipMethodID == 97 || ShippingMethod.ShipMethodID == 101)
            ).ShipMethodID
        }
        else {
            this.ArrayList = ConcatArray;
        }
        _.each(this.ArrayList, (obj) => {
            if(obj !== undefined){
                if (obj?.ParentId && obj?.ParentId !== obj.ItemCode) {
                    this.ParentItemCode = obj.ParentId.toString();
                }
                else {
                    this.ParentItemCode = '';
                }
                
                this.itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: this.ParentItemCode });
                if (obj.ItemCode !== "7800GC") {
                        obj.RetailPrice = obj.RetailPrice !== undefined ? obj.RetailPrice : 0;
                        this.cartSubtotal += parseFloat(obj.RetailPrice) * parseInt(obj.Quantity, 10);
                }
            }
        });
        
        if (type === 0 && (this._enrollSession.data.RetailOrderSummary.InitialOrders.length > 0 || ((sessionStorage.getItem("siteType")=='ultlifestyle'|| sessionStorage.getItem("siteType")=='ultpack') && this._enrollSession.data.RetailOrderSummary.Enrollpack.length > 0))) {
            if(this._enrollSession.data.RetailOrderSummary.InitialOrders.length == 1 && this._enrollSession.data.RetailOrderSummary.InitialOrders[0].ItemCode == "7800GC")
            {
                this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
            }
            this.disCountItem = this._enrollSession.data.RetailOrderSummary.InitialOrders.find((item, index) => {
                return item.ItemCode == "7800GC";
            });

            if (this.cartSubtotal < 200 && !this.disCountItem && sessionStorage.getItem("siteType")!='ultlifestyle') {
                this.discitem = this._getDiscountedItemsService.getDiscount(this.cartSubtotal);
                if (this.discitem !== undefined && this.discitem.itemCode !== '') {
                    this.itemDetail.push({ ItemCode: this.discitem.itemCode, Quantity: this.discitem.qty, ParentItemCode: '' });
                }
            }
            else if ((this.cartSubtotal >= 200||this.cartSubtotal < 75) && this.disCountItem) {
                const index = this._enrollSession.data.RetailOrderSummary.InitialOrders.indexOf(this.disCountItem);
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(index, 1)
                this.itemDetail = [];
                var newArrayList = this._enrollSession.data.RetailOrderSummary.InitialOrders.concat(this._enrollSession.data.RetailOrderSummary.InitialOrders);
                _.each(newArrayList, (obj) => {
                    if (obj.ParentId && obj.ParentId !== obj.ItemCode) {
                        this.ParentItemCode = obj.ParentId.toString();
                    }
                    else {
                        this.ParentItemCode = '';
                    }
                    this.itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: this.ParentItemCode });
                });
            }
            this.itemDetail = this.removeDuplicates(this.itemDetail);
            // console.log(this.itemDetail)
            this._enrollSession.data.OrderProductDetalis = this.itemDetail;
        }
        else 
            if (type === 0 && (sessionStorage.getItem("siteType")=='ultlifestyle' ||  sessionStorage.getItem("siteType")=='ultpack')) {
               

                this._enrollSession.data.OrderProductDetalis = this.itemDetail;
                
            }
            else {
                this._enrollSession.data.SmartOrderProductDetalis = this.itemDetail;
            }
      
       
        // Logic for price type after login customer
        if (this._enrollSession.data.IsLoggedIn) {
            if (this.customerDetail.CustomerType == 21 || this.customerDetail.CustomerType == 22) {
                this.priceType = this.customerDetail.PriceType;
            }
            if (this.customerDetail.CustomerType == 19 || this.customerDetail.CustomerType == 20) {
                if (this.cartSubtotal > 250 && type != 4) {
                    this.priceType = 10;
                }
                else if (this.cartSubtotal < 250 && type != 4) {
                    this.priceType = this.customerDetail.PriceType || this._enrollSession.data.priceType || 2;
                }
            }
        }
        if (this.cartSubtotal >= 200 && (!this._enrollSession.data.IsLoggedIn || this._enrollSession.data.LoggedInUserData.CustomerType == 18 || this._enrollSession.data.LoggedInUserData.CustomerType == 11) && type!=4) {
            this.priceType = 10;
            this._enrollSession.data.oldPricetype =this._enrollSession.data.priceType;

        }
        else if (this.cartSubtotal < 200 && (!this._enrollSession.data.IsLoggedIn || this._enrollSession.data.LoggedInUserData.CustomerType == 18 || this._enrollSession.data.LoggedInUserData.CustomerType == 11)  && type!=4) {
            this.priceType = 2;
            this._enrollSession.data.oldPricetype =this._enrollSession.data.priceType;
        }
        //this._enrollSession.data.oldPricetype = this._enrollSession.data.oldPricetype || this._enrollSession.data.priceType;
        this._enrollSession.data.priceType = this.priceType;
        try {
            const request = {
                ShipMethodId: type!=4 ? ( ShipMethodId ? ShipMethodId : (this._enrollSession.data.RetailEnroll.ShippingMethod ? this._enrollSession.data.RetailEnroll.ShippingMethod : 0)):(this.SmartOrderShippingMethod),
                ShippingCity: (this._enrollSession.data.RetailEnroll.City ? this._enrollSession.data.RetailEnroll.City :(this.isLogin? this.customerDetail.MainCity: '')),
                State: (this._enrollSession.data.RetailEnroll.State ? this._enrollSession.data.RetailEnroll.State :( this.isLogin? this.customerDetail.MainState: '')),
                Zip: (this._enrollSession.data.RetailEnroll.ZipCode ? this._enrollSession.data.RetailEnroll.ZipCode : (this.isLogin? this.customerDetail.MainZip: '')),
                Country: (this._enrollSession.data.RetailEnroll.CountryCode ? this._enrollSession.data.RetailEnroll.CountryCode : (this.isLogin? this.customerDetail.MainCountry: '')),
                Items: this.itemDetail,
                IsProfessional: false,
                IsNonprofit: false,
                PriceType:  type === 4 ? 10 : this.priceType,
                OrderType: type === 4 ? 4 : 0
            };
            this._restService.calculateRetailOrder(request).subscribe((result: any) => {
                if (result) {
                    // Abandon Cart JS Tracking
                    this.abandonedCart("Standard", result.Details, result.Total);
                    if (this.itemDetail.length > 0 && result.Warnings.length > 0) {
                        this._toastr.error(result.Warnings[0], 'Error');
                    }


                    this._enrollSession.data.calculateRetailOrderData = result;
                    this._enrollSession.data.calculateRetailOrder = result.CustomDetails;

                    if (type !== 4) {
                        this._enrollSession.data.ShippingMethods = [];
                        this._enrollSession.data.ShippingMethods = result.CustomShipMethods;
                        // this._enrollSession.data.ShippingMethods = result.CustomShipMethods ? result.CustomShipMethods.slice(0, 1) : result.CustomShipMethods;
                        this._enrollSession.data.RetailEnroll.ShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod ||
                            result.CustomShipMethods && result.CustomShipMethods[0].ShipMethodID;
                        const discountedItem = _.filter(result.CustomDetails, (item) => {
                            return item.ItemCode === '7800GC';
                        });

                        this.disCountItem = this._enrollSession.data.RetailOrderSummary.InitialOrders.find((item, index) => {
                            return item.ItemCode == "7800GC";
                        });
                        
                        this._enrollSession.data.RetailOrderSummary.InitialOrders =
                            _.without(this._enrollSession.data.RetailOrderSummary.InitialOrders, _.findWhere(this._enrollSession.data.RetailOrderSummary.Enrollpack, { ItemCode: "7800GC" }));

                            if (discountedItem.length > 0 && !this.disCountItem && this.cartSubtotal >= 75 && this.cartSubtotal <= 200) {
                            this._enrollSession.data.RetailOrderSummary.InitialOrders.push({
                                ItemCode: discountedItem[0].ItemCode,
                                Description: discountedItem[0].Description,
                                Quantity: discountedItem[0].Quantity,
                                Price: discountedItem[0].PriceTotal,
                                RetailPrice: discountedItem[0].PriceTotal,
                                ShippingPrice: discountedItem[0].ShippingPrice,
                                TinyImage: discountedItem[0].TinyPicture,
                                SmallImage: "assets/images/enhance-image/gift-card-img.png",//discountedItem[0].SmallPicture,
                                BV: discountedItem[0].BusinesVolume,
                                CV: discountedItem[0].CommissionableVolume,
                                TaxablePrice: discountedItem[0].Taxable,
                                LongDetail: discountedItem[0].LongDetail,
                                LongDetail2: discountedItem[0].LongDetail2,
                                LongDetail3: discountedItem[0].LongDetail3,
                                LongDetail4: discountedItem[0].LongDetail4,
                                IsUSOnly: false
                            });
                        }
                    }

                    if (this._enrollSession.data.ShippingMethods) {
                        this._enrollSession.data.ShippingMethods.forEach((method) => {
                            if (parseInt(method.ShipMethodID, 10) === parseInt(this._enrollSession.data.RetailEnroll.ShippingMethod, 10)) {
                                this._enrollSession.data.Order_descriptionField = method.Description;
                                this._enrollSession.data.Shipping_descriptionField = method.Description;
                                this._enrollSession.data.Order_shipMethodIDField = this._enrollSession.data.RetailEnroll.ShippingMethod;
                            }
                        });
                    }


                    /**** calculateRetailOrder ****/
                    this._enrollSession.data.orderdItemsData = [];
                    this._enrollSession.data.InitOrder?.forEach((item: any) => {
                        this._enrollSession.data.calculateRetailOrder.some((calcItem: any) => {
                            if (parseInt(calcItem.itemCodeField, 10) === item.ItemCode) {
                                this._enrollSession.data.orderdItemsData.push({
                                    ItemCode: calcItem.ItemCode,
                                    Quantity: calcItem.Quantity,
                                    // isDynamicKitChildItem:this._enrollSession.data.isDynamicKitChildItem,
                                    Price: calcItem.PriceEach,
                                    DescriptionField: calcItem.Description,
                                    PriceTotalField: calcItem.PriceTotal
                                });
                            }
                        });
                    });

                    /******************/

                    if (this.itemDetail.length > 0) {
                        if (result.Result.statusField === 0) {
                            if (callback) {
                                callback(result);
                            }
                        }
                        else {
                            this._toastr.error(result.Message);
                        }
                    }
                    this.balanceFieldPoint = parseFloat(sessionStorage.getItem('balanceFieldPoint'));
                    let Value = (Math.floor(this._enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal / 10) * 10);
                    if (Value > 0 && this.balanceFieldPoint>1000) {
                        this.DiscountOption = [];
                        for (var i = 10; i <= 50;) {
                            if (i <= Value && (i*100)<=this.balanceFieldPoint) {
                                this.DiscountOption.push(i);
                                i = i + 10;
                            }
                            else
                            {
                                break;
                            }
                        }
                    }
                    if(Value<this.DiscountValue)
                    {
                        this.DiscountValue = '';
                        this.totalRemainingBalance = '';
                        this.isSelected = false;
                    }
                    this.SmartOrderLength = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
                        return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
                      });
                }
            },
                error => {
                    console.log(error);
                    this._toastr.error('Unable to calculate order.', 'Error');
                });
        } catch (e) {
            this._toastr.error('Unable to calculate order.', 'Error');
        }
    }

    removeDuplicates(arr) {
        const uniqueItemCodes = new Set();
      
        return arr.filter(item => {
          const key = `${item.ItemCode}:${item.ParentItemCode}`;
          if (!uniqueItemCodes.has(key)) {
            uniqueItemCodes.add(key);
            return true;
          }
          return false;
        });
      }
      

    TotalItemLength(): any {
        if (this._enrollSession.data.customertype === 22) {
            if (this._enrollSession.data.checkoutwithlimited) {
                return this._enrollSession.data.RetailOrderSummary &&
                    (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
            }
            else {
                return this._enrollSession.data.RetailOrderSummary &&
                    (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack.length) +
                    (this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
            }
        }
        else {
            return this._enrollSession.data.RetailOrderSummary &&
                (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack.length) +
                (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
        }
    }
    Selected(event: any) {
        this.selectedDiscount = event.target.value;
    }
    RedeemDiscount(showNotification?:boolean) {
        this.DiscountValue = this.selectedDiscount;
        this.totalRemainingBalance = (this._enrollSession.data.RetailOrderSummary?.InitialOrderDetails?.Total - this.DiscountValue);
        //showNotification? this._toastr.success("Reward points applied.") : "";
    }
    public abandonedCart(type: string, items: Array<any>, total: number|string){
        try {
            const cartItems:any = [];
            if (items.length > 0) {
                items.forEach(element => {
                    cartItems.push({
                    "itemId": element.itemCodeField,
                    "productName": element.descriptionField,
                    "quantity": element.quantityField,
                    "qv": element.businesVolumeField,
                    "cv": element.commissionableVolumeField,
                    "price": element.priceEachField,
                    });
                });
            };

            let abandonedCartRequest: any = [{
                "type": type,
                "cartItem": cartItems,
                "price": total,
                "enrollerInfo": {
                    "customerId": this._enrollSession.data?.RetailEnroll?.EnrollerId,
                    "webAlias": this._enrollSession.data?.RetailEnroll?.webAliasField || this._enrollSession.data.CustomerWebAlias || this._enrollSession.data.ReferredBy
                },
                "customerInfo": {}
            }];

            if(this._enrollSession.data.IsUpGrade) { // Checking If customer is Logged In
                const customerInfo = {
                    "customerId": this._enrollSession.data?.UpgradableUserData?.CustomerID || '',
                    "firstName": this._enrollSession.data?.UpgradableUserData?.FirstName || '',
                    "lastName": this._enrollSession.data?.UpgradableUserData?.LastName || '',
                    "webAlias": this._enrollSession.data?.UpgradableUserData?.WebAlias || '',
                    "phoneNumber": this._enrollSession.data?.UpgradableUserData?.Phone || '',
                    "emailAddress": this._enrollSession.data?.UpgradableUserData?.Email || '',
                    "country": this._enrollSession.data?.UpgradableUserData?.MailCountry || '',
                    "region": this._enrollSession.data?.UpgradableUserData?.MailState  || '',
                }
                abandonedCartRequest = { ...abandonedCartRequest, customerInfo};
            }

            const tempData = JSON.stringify(abandonedCartRequest);
            window['ZTM'].setStats(LinkTracking.companyName, 'AbandonedCart', tempData);
        } 
        catch (error) {   
            console.error("Abandoned Cart Error", error);
        }
    }
}
