import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StepperModule } from '../stepper/stepper.module';
import { footerModule } from 'app/shared/footer/footer.module';
import { CalendarsComponent } from './calendar.component'; 

const routes: Routes = [
  {
    path: 'Calendar',
    component: CalendarsComponent
  }
];
@NgModule({
  declarations: [
    CalendarsComponent
  ],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgpSortModule,
    NgxChartsModule,
    FilterPipeModule,
    SummaryCartModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    StepperModule,
    footerModule
  ],
  exports: [
    RouterModule,
    FlexLayoutModule
  ],
  entryComponents: [

  ],
  providers: [
  ]
})
export class CalendarsModule { }
