<app-header></app-header>
<div style="max-width: 1400px; margin:auto;">


    <!-- Hero Image -->
    <div class="container">
        <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/incentives-1.png" />
    </div>

    <!-- Accordeon -->
    <div class="container">
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" style="background-color: #80927E; color: white; border-radius: 0;"
                        type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne" (click)="toggleFirstPurchase()">
                        Purium's Incentives Menu
                    </button>
                </h2>
                <div *ngIf="showList" id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body" style="background-color: #f8f8f8;">
                        <ul class="list-unstyled">
                            <li><a (click)="smoothscroll('#FirstPurchase')"
                                style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;"
                                class="hover-green">First Purchase Incentives</a></li>
                            <li><a (click)="smoothscroll('#loyalcus')"
                                    style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;"
                                    class="hover-green">25% Loyal Customer</a></li>
                            <li><a (click)="smoothscroll('#smartorder')"
                                    style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;"
                                    class="hover-green">Monthly Subscription</a></li>
                            <li><a (click)="smoothscroll('#rewards')"
                                    style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;">Rewards Points</a></li>
                            <li><a (click)="smoothscroll('#3andfree')"
                                    style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;">3 &
                                    Then Free</a></li>
                            <li><a (click)="smoothscroll('#upgrade')"
                                    style="text-decoration: none;font-weight: 500;color: #000;cursor: pointer;">Upgrade
                                    to a Brand Partner</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- First Purchase Incentives -->
    <div class="container mt-5" id="FirstPurchase">
        <h1 class="lora-bold text-center m-3 fs-55" style="color: #617960">First Purchase Incentives</h1>
        <p class="text-center purchase-text-css">Use your Brand Partner's Gift Code and save on your first Purium order.<br> The more you
            shop, the more you save. Discounted pricing begins when your first purchase is <br> at least $75. Subscribe to
            Smart Order upon checkout for future self & savings.</p>
        <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/Mockup - iShopPurium Incentives Page 1.png" />
        <div class="row  mt-5 mb-5">
            <div class="col text-center">
                <h1 class="js-bold m-0" style="color: #617960">$50 Off</h1>
                <p>Shop $75</p>
            </div>
            <div class="col text-center">
                <h1 class="js-bold m-0" style="color: #617960">25% Off</h1>
                <p>Shop $200+</p>
            </div>
            <div class="col text-center">
                <h1 class="js-bold m-0" style="color: #617960">Save $10 on S&H</h1>
                <p>with orders of $250 or more!</p>
            </div>
        </div>
    </div>

    <!-- Returning Customers -->
    <style>
        .image_off,
        .loyalcus:hover .image_on {
            display: none
        }

        .image_on,
        .loyalcus:hover .image_off {
            display: block
        }
    </style>

    <div class="padding">
        <div class="divider-line"></div>
    </div>

    <div id="loyalcus" style="background-color: #f8f8f8;">
        <div class="container text-center">
            <h1 class="lora-bold pt-5 pb-3" style="color: #3A4C8D">Returning Customers<br> Shop More, <i>Save More</i>
            </h1>
            <p class="mb-5">Order every month to maximize your savings.
                <!-- <br>Free or reduced shipping at orders of $250 &
                more. -->
            </p>
            <div class="row">
                <div class="col ">
                    <a class="loyalcus"><img class="image_on loyalcusImage" src="../../../../assets/img/logos/inc-1.png" alt=""
                            style="margin:auto;" /><img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-12.png"
                            alt="" style="margin:auto;" /></a>
                </div>
                <div class="col">
                    <a class="loyalcus"><img class="image_on loyalcusImage" src="../../../../assets/img/logos/inc-2.png" alt=""
                            style="margin:auto;" /><img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-22.png"
                            alt="" style="margin:auto;" /></a>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <a class="loyalcus"><img class="image_on loyalcusImage" src="../../../../assets/img/logos/inc-4.png" alt=""
                            style="margin:auto;" />
                        <img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-33.png" alt=""
                            style="margin:auto;" />
                    </a>
                </div>
                <div class="col">
                    <a class="loyalcus"><img class="image_on loyalcusImage" src="../../../../assets/img/logos/inc-3.png" alt=""
                            style="margin:auto;" />
                        <img class="image_off loyalcusImage" src="../../../../assets/img/logos/inc-45.png" alt=""
                            style="margin:auto;" />

                    </a>
                </div>

            </div>
        </div>
    </div>
    
    <div class="padding">
        <div class="divider-line"></div>
    </div>
    <!-- Smart Order Subscriptions Incentives -->
    <!-- <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/incentives-3.png" /> -->
    <ng-container id="smartorder">
        <div id="smartorder" class="banner-images mt-5 collection-padding-subscribe">
            <img class="banner-image-width-1" src="../../../assets/images/Banner/MonthlyWeb.png">
            <img class="banner-image-width-2" src="../../../assets/images/Banner/Calender.png">
          </div>
        <div >
            <div class="p-5">
                <ul class="mt-3">
                    <li><h1 class="fw-normal fs-medium-text">Commit to your healthy habits</h1></li>
                    <br>
                    <li><h1 class="fw-normal fs-medium-text">Ensure the maximum discount every month of 25% off</h1></li>
                    <br>
                    <li><h1 class="fw-normal fs-medium-text">Qualify for Free Product Promotion 3 & Then Free</h1></li>
                </ul>
            </div>
        </div>
    
        <div class="container text-center">
            <h1 style="color:#397168;">Processing Type:</h1>
            <div class="row mt-5">
                <div class="col flex-div">
                    <img class="img-fluid time-box" src="../../../../assets/img/logos/inc-time.png" />
                    <div class="flex-div1 margintop">
                        <h1 class="lora-bold-text" style="color: #1A1A1A">Always <br>Ship</h1>&nbsp;&nbsp;
                        <img (click)="OpenAlwaysShipPopup()" class="margin-info" src="../../../../assets/icons/info 9.png" width="20">
                    </div>
                </div>
                <div class="col flex-div">
                    <img class="img-fluid time-box" src="../../../../assets/img/logos/inc-box.png" />
                    <div class="flex-div1">
                        <h1 class="lora-bold-text" style="color: #1A1A1A">Backup <br>Order</h1>&nbsp;&nbsp;
                        <img (click)="OpenBackUpOrderPopup()" class="margin-info" src="../../../../assets/icons/info 9.png" width="20">
                    </div>
                </div>
            </div>
            <p class="mt-5 fs30">"Always Ship" is the default for most orders,<br>yet you can edit in your iShopPurium.com
                Account.</p>
        </div>
        
        <img class="img-fluid pb-5" style="margin:auto;" src="../../../../assets/img/imgtext.png" />
    
    </ng-container>

    <div class="padding">
        <div class="divider-line"></div>
    </div>

    <!-- Reward Points Section -->
    <div id="rewards">
        <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/incentives-7.png" />
    </div>

    <!-- 3 & then free -->
    <div class="padding">
        <div class="divider-line"></div>
    </div>
    <div id="3andfree" class="text-center">
        <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/Incentives3&free/3leaf01.png" />
    </div>
    <div id="3andfree" class="container text-center mt-5 mb-5">
        <div class="text-center text-color f-w-bold pb-5">
            <span class="f-s-30">How does it work?</span>
        </div>
        <h4 class="text-purchase">Purchase one of the products below for three consecutive months, and have an <br> active Smart Order, you receive
            the product for FREE in the fourth month.
        </h4>
        <div class="row mt-5 align-items-end align-items-media">
            <div class="col">
                <img class="img-fluid img-width" style="margin:auto;" src="../../../../assets/img/Incentives3&free/Heart-Aid.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Heart Aid</p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" style="margin:auto;" src="../../../../assets/img/Incentives3&free/EpiDeGenus.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Epi-Genius Dogs & Cats
                </p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" style="margin:auto;" src="../../../../assets/img/Incentives3&free/Women's Defense.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Women’s Defense</p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" style="margin:auto;" src="../../../../assets/img/Incentives3&free/Men's Defense.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Men’s Defense</p>
            </div>
        </div>
        <div class="row mt-5 align-items-baseline align-items-media">
            <div class="col">
                <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/SuperLytes.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Super Lytes</p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/Control Pre-Meal Capsules.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Control <br> Pre-Meal Capsules</p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/KamutBlend01.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Kamut Blend
                </p>
            </div>
            <div class="col">
                <img class="img-fluid img-width" src="../../../../assets/img/Incentives3&free/Barley Green Juice.png" />
                <p class="font-text m-0 pt-3" style="color: #397168;">Barley <br> Green Juice
                </p>
            </div>
        </div>
    </div>
    <div class="padding">
        <div class="divider-line"></div>
    </div>

    <div id="post"></div>

    <!-- Interested in Sharing Purium -->
    <div id="share" class="container text-center mt-5">
        <br><br>
        <h1 class="lora-bold" style="color:#CA714B">Interested in Sharing Purium with your own Gift Code?</h1>
        <h4 class="pt-3">How much you earn depends on how much you share.</h4>
        <div class="m-5">
            <h1 class="" style="color:#3A5838;">Pay for Your Products</h1>
            <p>Share products with people you know.</p>
            <hr class="mt-4 border-line">
        </div>
        <div class="m-5 pt-1">
            <h1 class="" style="color:#9261B8;">Pay for Your Lifestyle</h1>
            <p>Build a team of Brand Partners<br>who love to change the world together.</p>
            <hr class="mt-4 border-line">
        </div>
    </div>


    <div id="upgrade" class="container text-center mb-5 pb-5">
        <h3><i>Reach out to the person who referred you to<br>Purium to learn more today!</i></h3>
    </div>
    <img class="img-fluid" style="margin:auto;" src="../../../../assets/img/incentives-6.png" />

</div>
<app-footercomponent></app-footercomponent>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">