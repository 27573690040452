import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  public userEmail: any;
  constructor(
    public _enrollSession: EnrollSessionService,
    public _router: Router,
    public _formBuilder: FormBuilder,
    public _restService: RestService,
    public _toastr: ToastrService
    
    ) { 
    this.forgotPasswordForm = this.createForgotPasswordForm()
  }

  ngOnInit(): void {
  }
  createForgotPasswordForm(): FormGroup {
    return  this._formBuilder?.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)])]
    })
  }

  continue() {
    this._router.navigate(['/Login'])
  }

  forgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.validateAllFormFields(this.forgotPasswordForm);
      return;
    }
    var request = {
      'email': this.userEmail
    }

    this._restService.ForgotPassword(request).subscribe(result => {
      if (result) {
        //this._toastr.success('A reset link send to your email is succesfully', "Success");
      }
    }, error => {
      console.log(error);
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
