import { NgModule } from '@angular/core';
import { SearchPipe } from './search-pipe';
import { SafeHtmlPipe } from './safehtml-pipe';
import { SafeUrlPipe } from './safeurl-pipe';

@NgModule({
    declarations: [
        SearchPipe,
        SafeHtmlPipe,
        SafeUrlPipe

    ],
    imports     : [],
    exports     : [
        SearchPipe,
        SafeHtmlPipe,
        SafeUrlPipe
    ]
})
export class PipesModule
{
}
