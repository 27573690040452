import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
// import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import * as _ from 'underscore';
// import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { SubscribeComponent } from './Subscribe.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { footerModule } from 'app/shared/footer/footer.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { ShipPopupDialogModule } from './shipPopUp-dialog/ship-popup-dialog.module';
import { BackUpOrderPopupDialogModule } from './BackUpOrder-dialog/backupOrder-popup-dialog.module';
import { SubscriptionInfoPopupDialogModule } from './SubscriptionInfo-dialog/subscriptionInfo-popup-dialog.module';
import { SubscriptionDatePopupDialogModule } from './SubscriptionDate-dialog/subscriptionDate-popup-dialog.module';
import { MatInputModule } from '@angular/material/input';

const routes: Routes = [
    {
        path: 'Subscribe',
        component: SubscribeComponent
    },
    {
        path: 'Subscribe/:id',
        component: productInfoComponent,
        pathMatch:'full'
    }
];

@NgModule({
    declarations: [
        SubscribeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        // MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        // MatSelectModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        // MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        SummaryCartModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule,
        footerModule,
        MatSidenavModule,
        MatRadioModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ShipPopupDialogModule,
        BackUpOrderPopupDialogModule,
        SubscriptionInfoPopupDialogModule,
        SubscriptionDatePopupDialogModule,
        MatInputModule

    ], entryComponents: [
        
    ],
    providers: [
    ],
    exports: [
    ]
})
export class SubscribeModule {
}

