import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { startOfDay, startOfWeek, addWeeks, subWeeks, startOfMonth, endOfWeek, subMonths, addMonths, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, getMonth } from 'date-fns';
import { CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { Location,LocationStrategy, PathLocationStrategy } from '@angular/common';
type CalendarPeriod = 'month';

@Component({
  selector: 'subscriptionDate-popup-dialog',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './subscriptionDate-popup-dialog.component.html',
  styleUrls: ['./subscriptionDate-popup-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionDatePopupDialogComponent implements OnInit {
  currentRouterPath: any;
  currentYear = new Date().getFullYear();
  viewDate: any = this._enrollSession.data.smartOrderDate ? this._enrollSession.data.smartOrderDate : addMonths(new Date(), 1);
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  datesDisabled: any;
  _selectedDay: any;
  AutoShipStartDate: any;
  prevBtnDisabled: boolean = false;
  LoggedInUserData: any;
  nextBtnDisabled: boolean = false;
  checked: boolean = true;
  minDate: Date = subMonths(new Date(), 0);
  maxDate: Date = addMonths(new Date(), 1);
  disableDaysOfMonth = [22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  currentMonth=+new Date().getMonth()+1;
  smartOrderDateForLifestyle: any;
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<SubscriptionDatePopupDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
  }

  ngOnInit(): void {
    console.log("refrseh",this.refresh);
  }

dateIsValid(date: Date): boolean {
    //return date.getMonth()+1!==this.currentMonth && date.getTime() > new Date().getTime();
    return parseInt(moment().add(1, "M").format('MM')) === date.getMonth()+1  && date.getTime() > new Date().getTime();
}
beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
        if (!this.dateIsValid(day.date)) {
            day.cssClass = 'disabled-date';
        }
        const _selectedDay = moment(day.date).format('MM/DD/YYYY') === this._enrollSession.data.smartOrderDate ? day : "";
        if (_selectedDay) {
            _selectedDay.cssClass = 'cal-selected';
        }
    });
}
getFirstAndThirdWed(year, month) {
    let currmonth = (parseInt(month, 10) + 1);
    let arrdays = [];
    let dateinmonth = moment().daysInMonth();
    while (dateinmonth) {
        var current = moment().date(dateinmonth);
        arrdays.push(current);
        dateinmonth--;
    }

    var enabledDatesArr = [];
    for (var y = year; y <= 2030; y++) {
        for (var j = currmonth; j <= 12; j++) {
            //enabledDatesArr.push(moment(j + '/1/' + y).format('MM-DD-YYYY'));
            for (var i = 22; i <= 31; i++) {
                var dat = j + '/' + i + '/' + y;
                enabledDatesArr.push(moment(dat).format('MM-DD-YYYY'));
            }
        }
    }
    arrdays.forEach(element => {
        if (moment(element._d).isSameOrBefore(moment())) {
            enabledDatesArr.push(moment(element._d).format('MM-DD-YYYY'));
        }
    });
    return enabledDatesArr;
}
changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
}
monthIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
}
dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.monthIsValid(
        this.endOfPeriod(this.viewDate, this.subPeriod('month', this.viewDate, 1))
    );
    this.nextBtnDisabled = !this.monthIsValid(
        this.startOfPeriod(this.viewDate, this.addPeriod('month', this.viewDate, 1))
    );
    if (this.viewDate < this.minDate) {
        this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
        this.changeDate(this.maxDate);
    }
}
increment() {
    this.changeDate(this.addPeriod('month', this.viewDate, 1));
}
decrement() {
    this.changeDate(this.subPeriod('month', this.viewDate, 1));
}
dayClicked({ date, }: { date: Date; }): void {
    var maxMonth = getMonth(this.maxDate);
    if ((getMonth(date) && getMonth(date) <= maxMonth) || (getMonth(date) == 0 && getMonth(date) <= maxMonth)) {
        if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true)) {
            this.activeDayIsOpen = false;
        } else {
            this.activeDayIsOpen = true;
        }
        this.viewDate = moment(date).format('MM/DD/YYYY');
        this._enrollSession.data.smartOrderDate = this.viewDate;
        this.AutoShipStartDate = this.viewDate;
        localStorage.setItem("AutoShipStartDate", JSON.stringify(this.AutoShipStartDate));
    }
}
startOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
        day: startOfDay,
        month: startOfMonth,
    }['month'](date);
}
endOfPeriod(period: CalendarPeriod, date: Date): Date {
    return {
        day: endOfDay,
        week: endOfWeek,
        month: endOfMonth,
    }['month'](date);
}
addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
        day: addDays,
        week: addWeeks,
        month: addMonths,
    }['month'](date, amount);
}
subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
    return {
        day: subDays,
        week: subWeeks,
        month: subMonths,
    }['month'](date, amount);
}
  closePopup(){
    this.dialogRef.close();
  }
}
