import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
// import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import * as _ from 'underscore';
// import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { footerModule } from 'app/shared/footer/footer.module';
import { CollectionsComponent } from './collections.component';
import { LifestyleSubscriptionComponent } from 'app/shared/LifestyleSubscription/LifestyleSubscription.component';
import { MatSidenavModule } from '@angular/material/sidenav';

const routes: Routes = [
    {
        path: 'Collection1',
        component: CollectionsComponent
    },
    {
        path: 'Collection2',
        component: CollectionsComponent
    },
    {
        path: 'Collection3',
        component: CollectionsComponent
    },
    {
        path: 'Collection4',
        component: CollectionsComponent
    },
    {
        path: 'Collection5',
        component: CollectionsComponent
    },
    // {
    //     path: 'Collection6',
    //     component: CollectionsComponent
    // },
    {
        path: 'Collection7',
        component: CollectionsComponent
    },
    {
        path: 'Collection8',
        component: CollectionsComponent
    },
    {
        path: 'Collection9',
        component: CollectionsComponent
    },
    {
        path: 'Collection10',
        component: CollectionsComponent
    },
    {
        path: 'IRT',
        component: CollectionsComponent
    },
];

@NgModule({
    declarations: [
        CollectionsComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        // MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        // MatSelectModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        // MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        SummaryCartModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule,
        footerModule,
        MatSidenavModule

    ], entryComponents: [
        LifestyleSubscriptionComponent
    ],
    providers: [
    ],
    exports: [
    ]
})
export class CollectionModule {
}

