import { Component, EventEmitter, HostListener, Input, OnInit, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import * as _ from 'underscore';
import { SubscribeDialogComponent } from 'app/shared/subscribe-dialog/subscribe-dialog.component';
import { GetDiscountedItemsService } from 'app/shared/getDiscountedItems.service';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import { takeUntil } from 'rxjs/operators';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'app/shared/auth.service';
import { RestService } from 'app/shared/rest.service';
import { VerifyAddressComponent } from 'app/shared/verify-address/verify-address.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { API_URL } from 'app/constants';
import * as moment from 'moment';
import { CommonService } from 'app/shared/common.service';
import { RgetRegexService } from 'app/shared/getRegex.service';
@Component({
    selector: 'summary-cart-button',
    templateUrl: './summary-cart-button.component.html',
    styleUrls: ['./summary-cart-button.component.scss'],
    animations: [
        trigger('slideInOut', [
          state('in', style({ transform: 'translateX(0)' })),
          state('out', style({ transform: 'translateX(-100%)' })),
          transition('in => out', animate('300ms ease-out')),
          transition('out => in', animate('300ms ease-in')),
        ]),
      ]
})
export class SummaryCartComponentButton implements OnInit, OnDestroy {
    @Input() routPath: any;
    @Input() customerInfoForm: any;
    @Input() shippingForm: any;
    @Input() shippingMethodForm: any;
    @Input() referredForm: any;
    @Input() billingAddressForm: any;
    @Input() paymentTypeForm: any;
    @Input() subscribeDialogShow: any;
    @Input() accountInfoForm: any;

    dialogRef: any;
    currentRouterPath: any;
    finalShippingMethod: any;
    siteType: any;
    customerDetail: any = '';
    priceType: any ;
    backofficeUrl: any;
    packSectionTitle: any;
    public isLogout: boolean = false;
    public selectedDiscount: any;
    public DiscountValue:any;
    isSelected:boolean = false;
    public totalRemainingBalance:any;
    private _unsubscribeAll: Subject<any>;
    public hasLower: boolean = false;
    public hasUpper: boolean = false;
    public hasNum: boolean = false;
    public hasSpecial: boolean = false;
    public hasLength: boolean = false;
    mode = new FormControl('over' as MatDrawerMode);
    ProductCode: any;
    SmartOrderDate: any;
    LoggedInUser:any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    SubscriptionProcessType: any;
    CustomAutoOrderList: any;
    invalidzip1:any;
    constructor(
        private _toastr: ToastrService,
        private _matDialog: MatDialog,
        private _restService: RestService,
        private router: Router,
        public _enrollSession: EnrollSessionService,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        public _sharedMethodService: SharedMethodService,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        private ref: ChangeDetectorRef,
        private cookieService: CookieService,
        private _auth: AuthService,
        private _activeRoute: ActivatedRoute,
        public _commonService: CommonService,
        public _RgetRegexService: RgetRegexService

    ) {
        this.ProductCode = this._activeRoute.snapshot.paramMap.get('id');

        this.currentRouterPath = this.router.url.split('?')[0];
        if (this._enrollSession.data.RetailOrderSummary == null && this.currentRouterPath != '/AllProducts/' + this.ProductCode && this.CollectionRoute() && this.currentRouterPath != '/homepage' && this.currentRouterPath != '/documents/privacy' && this.currentRouterPath != '/documents/shipping' && this.currentRouterPath != '/documents/support' && this.currentRouterPath != '/Calendar' && this.currentRouterPath != '/documents/incentives' && this.currentRouterPath != '/Ultlifestyle' && this.currentRouterPath != '/ResetShopifyPassword') {
            this.router.navigate(['/AllProducts']);
        }
        this._unsubscribeAll = new Subject();
        this.finalShippingMethod = this.finalShippingMethod || 0;
        this.siteType = sessionStorage.getItem("siteType");
    }

    ngOnInit(): void  {
        this._calculateRetailOrderService.cartSubtotal = 0;
        var cartSubTotal = 0;
        _.each(this._enrollSession.data?.RetailOrderSummary?.InitialOrders, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary?.Enrollpack, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        this._calculateRetailOrderService.cartSubtotal = cartSubTotal;
        this.currentRouterPath = this.router.url.split('?')[0];
        this._sharedMethodService.checkSubTotal.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res) {
                if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }
                // this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
            }
        })
        if (this._auth.isLoggedIn()) {
            this.isLogout = true;
            if (this.customerDetail=='') {
                setTimeout(() => {
                    this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
                    this.priceType = this._enrollSession.data.priceType;
                }, 2000);   
            }
        }
        this.customerDetail = JSON.parse(localStorage.getItem('LoggedInUserData')) || {};
        this.priceType = this._enrollSession.data.priceType;

        if (this.siteType == 'fruitsandveggie') {
            this.packSectionTitle = 'Fruit & Veggie+ Pack';
        }
        else if (this.siteType == 'collagen') {
            this.packSectionTitle = 'Collagen Pack';
        }
        else {
            this.packSectionTitle = 'Transformation Pack';
        }
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    CollectionRoute(){
        if(this.currentRouterPath != '/Collection1' && this.currentRouterPath != '/Collection2' && this.currentRouterPath != '/Collection3' && this.currentRouterPath != '/Collection4' && this.currentRouterPath != '/Collection5' && this.currentRouterPath != '/Collection7' && !this.currentRouterPath.includes('/IRT')){
            return true;
        }
        else{
            return false
        }
    }
    subscribeDialog() {
        if ((this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/"+this.ProductCode)  && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
            if(window.innerWidth < 550){
                this.closeMobileSummaryCart();
              }
              else{
                  this._sharedMethodService.toggleSidenavforaddtocart();
              }
            this.onClickChange();
        }
        else if (this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/"+this.ProductCode) {
            this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '375px',
                panelClass: '',
                disableClose: true
            });
            this.dialogRef.afterClosed()
                .subscribe(response => {
                    if (!response) {
                        return;
                    } else {
                        if (response == 'false') {
                            if(window.innerWidth < 550){
                                this.closeMobileSummaryCart();
                              }
                              else{
                                  this._sharedMethodService.toggleSidenavforaddtocart();
                              }
                            this.onClickChange();
                            this.router.navigate(['/Shipping']);
                        }
                        if (response == 'true') {
                           return
                        }
                    }

                });

        }
    }

    onClickChange(isCheckout?:boolean) {
        if (window.innerWidth < 550 && (this.currentRouterPath != "/Subscribe" && this.currentRouterPath != "/Subscribe/" + this.ProductCode)) {
            this.closeMobileSummaryCart();
        }
        if (this.referredForm || this.customerInfoForm || this.shippingForm || this.shippingMethodForm) {
            if (this.shippingForm && this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 = "";
            }
            if ((!this._auth.isLoggedIn() && this.referredForm.invalid) || this.customerInfoForm.invalid || this.shippingForm.invalid || this.shippingMethodForm.invalid) {
                if (!this._auth.isLoggedIn() && this.referredForm.invalid) {
                    this.validateAllFormFields(this.referredForm);
                    this._toastr.error('Please fill the referral code', 'error');
                    return;
                }
                if ( this.customerInfoForm.invalid) {
                    this.validateAllFormFields(this.customerInfoForm);
                    this._toastr.error('Please fill all the customer info.', 'error');
                }
                if (this.shippingForm.invalid) {
                    this.validateAllFormFields(this.shippingForm);
                    this._toastr.error('Please fill all the shipping info.', 'error');
                }
                if (this.shippingMethodForm.invalid) {
                    this.validateAllFormFields(this.shippingMethodForm);
                    this._toastr.error('Please fill shipping method and secure shipping option.', 'error');
                }
                return;
            }
        }

        if (this.billingAddressForm || this.paymentTypeForm) {
            if (this.billingAddressForm && this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 = "";
            }
            if (this.billingAddressForm.invalid || this.paymentTypeForm.invalid) {
               
                if (this.billingAddressForm.invalid) {
                    this.validateAllFormFields(this.billingAddressForm);
                    this._toastr.error('Please fill all required fields', 'error');
                    return;
                }
                if (this._auth.isLoggedIn() ? (this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == "true" ? this.paymentTypeForm.valid : this.paymentTypeForm.invalid) : this.paymentTypeForm.invalid) {
                    this.validateAllFormFields(this.paymentTypeForm);
                    this._toastr.error('Please fill all required fields', 'error');
                    return;
                }
            }
        }
        if(this.currentRouterPath.includes("Shipping")){
            this.regexValidateZip(this._enrollSession.data.RetailOrderSummary?.customerInfoData?.ZipCode);
            if(this.invalidzip1){
                return this._toastr.error('Please check your zipcode.','error');
            }
        }

        if (this.currentRouterPath == '/AllProducts' && (this._enrollSession.data.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary.InitialOrders == undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) && (!this.siteType || this.siteType == null || this.siteType == '')) {
            this._toastr.warning("Please select an item", '');
            return;
        }
        if (this.siteType!='ultlifestyle' && this.currentRouterPath == "/Subscribe" && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && (this._enrollSession.data.smartOrderDate == undefined || this._enrollSession.data.smartOrderDate == null || this._enrollSession.data.smartOrderDate == '')) {
            this._toastr.error('Please select Smart Order Date', 'error');
            const firstElementWithError = document.querySelector('.dateValidation') as HTMLElement;
            if (firstElementWithError) {
                //   firstElementWithError.scrollIntoView({ behavior: 'smooth' });
                const scrollPositionAbove = firstElementWithError.offsetTop - 100; // Adjust the value as needed
                window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
            }
            return;
        }
        else
        if(this.siteType!='ultlifestyle' && this.currentRouterPath == "/Subscribe"){
            this.router.navigate(['/Shipping']);
        }

        if (this.isLoginCartButton() && (this.currentRouterPath.includes("AllProducts") || this.currentRouterPath.includes("Collection") || this.currentRouterPath.includes("cantepack"))) {
            this.router.navigate(['/Shipping']);
        }
        if (this.routPath == 'Shipping' && this.currentRouterPath == '/Subscribe' && this.siteType == 'ultpack') {
            return;
        }
        if (this.routPath == 'Checkout' && this.currentRouterPath == '/Shipping' && this.accountInfoForm.value.Password !== "" && !this._auth.isLoggedIn()){
            this.validatePassword(this.accountInfoForm.value.Password);
            if (this.hasLower == false || this.hasLength == false || this.hasUpper == false || this.hasNum == false || this.hasSpecial == false) {
                this._toastr.error('Please check your Password', 'error');
                return;
            }
        }
        if (this.currentRouterPath.includes("Shipping") && !this._auth.isLoggedIn() && (this._enrollSession.data.CustomerID === null || this._enrollSession.data.CustomerID === '' || this._enrollSession.data.CustomerID === undefined)){
            this._commonService.createCustomer(18);
        }
        if (this.currentRouterPath === "/Shipping" && !this._auth.isLoggedIn() && this._enrollSession.data.RetailOrderSummary.customerInfoData.Email && (this._enrollSession.data.CustomerID === null || this._enrollSession.data.CustomerID === '' || this._enrollSession.data.CustomerID === undefined)) {
                const mailrequest = '?email=' + this._enrollSession.data.RetailOrderSummary.customerInfoData.Email;
                this._restService.IsEmailRegistered(mailrequest).subscribe((result: any) => {
                    if (!result) {
                        this._toastr.error('Email Already Registered', 'Error');
                        this.customerInfoForm.get("Email").reset();
                        return;
                    } else {
                        //this._toastr.success('Email Available', 'Success');
                        if (this.routPath != "" && this.routPath != null && (this.routPath != "Shipping" || this.siteType == 'ultlifestyle' || this.siteType == 'ultpack')) {
                            if (this.routPath == 'Checkout' && this.currentRouterPath == '/Shipping' && this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode == 'US') {
                                this.suggestedAddress();
                                return ;
                            }
                            else { this.router.navigate(['/', this.routPath]); }
                        }
                    }
                })
        }
        else {
            if (this.routPath != "" && this.routPath != null && (this.siteType==null || this.siteType == "" || this.siteType == 'ultlifestyle' || this.siteType == 'ultpack')) {
                if (this.routPath == 'Checkout' && this.currentRouterPath == '/Shipping' && this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode == 'US') {
                    this.suggestedAddress();
                    return ;
                }
                else if (isCheckout && this.routPath == 'Review-Page' && (!this._auth.isLoggedIn() ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == 'true' : this._enrollSession.data.RetailOrderSummary.paymentTypeData.primaryPaymentCard == 'addNewCard')) { this.fraudPrevention() }
                else { this.router.navigate(['/', this.routPath]); }
            }
        }
    }
    regexValidateZip(zipCode): void {
        const customerInfoForm = this.customerInfoForm ? this.customerInfoForm.getRawValue() : {};
        const regxExp = this._RgetRegexService.getRegex(customerInfoForm.CountryCode);
        const filter = new RegExp(regxExp);
        if (filter.test(zipCode)) {
            this.invalidzip1 = false;
        } else if (zipCode && (zipCode.indexOf(' ') !== 0)) {
            this.invalidzip1 = true;
        }
        else {
            this.invalidzip1 = false;
        }
    }
    fraudPrevention() {
        let tokenCheck:boolean = true;
        this._sharedMethodService.tokenizer.tokenize({
          success: (data) => {
            var token = data.token;
            $(".creditcard-token").val(token);
            $(".creditcard-display").val(token.substr(token.length - 4, 4));
            this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber = token;
            this._enrollSession.data.RetailEnroll.CardType = data.cardType;
            this._enrollSession.data.RetailOrderSummary.paymentTypeData.tokenize = data;
            this._commonService.validateCardNumber(this._enrollSession.data.RetailOrderSummary.paymentTypeData?.cardNumber);
            if(tokenCheck){
                if (this._commonService.hasCardNumberCheck === false) {
                    this._toastr.error('Please check your Card Number', 'error');
                    return;
                }
                this.router.navigate(['/', this.routPath]);
            }
            tokenCheck = false;
          },
          error: (data) => {
            this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber= "";
            this._enrollSession.data.RetailEnroll.CardType = "";
            if(tokenCheck){
            this._toastr.error(data?.message,'error');
            }
            tokenCheck = false;
            return;
          }
        });
    }
    removedOrder(index, Quantity, parentId, orderType) {
        if (orderType == 'todayOrder') {
            this.removeInitOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'smartOrder') {
            this.removeSmartOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'transformation') {
            this.EditTransformPack();
        }
        if ((this._enrollSession.data.RetailOrderSummary == undefined) || this._enrollSession.data.RetailOrderSummary.InitialOrders == undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0) &&  (!this.siteType || this.siteType==null||this.siteType=='')) {
            this._toastr.warning("Please select an item", '');
            this.router.navigate(['/AllProducts']);
        }
    }
    // ****removeSmartOrder****/
    removeSmartOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');

        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
        });
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
    }
    // ****removeInitOrder****/
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
            }
        }
        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
        });
        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId && item.ParentId !== undefined;
        });

        this._enrollSession.data.InitialOrdersSubItems = '';
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
                
        const firstElementWithError = document.querySelector('.ng-invalid') as HTMLElement;
        if (firstElementWithError) {
            const scrollPositionAbove = firstElementWithError.offsetTop - 200; // Adjust the value as needed
            window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
        }
    }
    EditTransformPack() {
        this._enrollSession.editTransformationPack = "true";
        this.router.navigate(['/AllProducts']);
    }
    closeMobileSummaryCart() {
        this._enrollSession.SummaryCartMobOnly = !this._enrollSession.SummaryCartMobOnly;
    }
    currentPage()
    {
        return window.innerWidth < 550 && this.currentRouterPath != '/Confirmation' && this.currentRouterPath != '/Ultlifestyle' && this.currentRouterPath != '/ResetShopifyPassword'
    }
    desktopButton()
    {
        return window.innerWidth > 550 && this.currentRouterPath != '/homepage' && this.currentRouterPath != '/Confirmation' && this.currentRouterPath != '/Ultlifestyle' && this.currentRouterPath != '/ResetShopifyPassword'
        // return this.currentRouterPath=='/AllProducts'
    }
    ngDoCheck() {
        this.ref.markForCheck();
    }
    redirectToBackoffice(){
        let windowReference = window.open();
        this._restService.SilentLogin(this._enrollSession.data.LoggedInUserData.CustomerID).subscribe((res:any) => {
            this.backofficeUrl=res;
            // window.open(this.backofficeUrl, "_blank");
            windowReference.location = res;
          }, error => {
            console.log("In error")
          })
    }
    suggestedAddress()
    {
        let reqAddress = {
            address1 : this._enrollSession.data.RetailOrderSummary.shippingFormData.address1,
            city : this._enrollSession.data.RetailOrderSummary.shippingFormData.City,
            state: this._enrollSession.data.RetailOrderSummary.shippingFormData.State,
            countryCode: this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode,
            zip: this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode
          }

        
        this._restService.GetVerifedAddressResponseV1(reqAddress).subscribe((response : any)=>
        {
            if (!response.Data.isVerified) {
                this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
                    width: '550px',
                    maxWidth: '550px',
                    height: '456px',
                    panelClass: '',
                    data: {
                        suggestedAddress: {},
                        userAddress: reqAddress,
                        InvalidAddress: true
                    },
                    disableClose: true
                });
                this.dialogRef.afterClosed()
                    .subscribe(response => {
                        if (response.continue) {
                            console.log("response", response.continue);
                            this.router.navigate(['/', this.routPath]);
                        }
                        else { return; }
                    });
            }
            let resAddress = {
                address1 : response.Data.response && response.Data.response.addressField !== null ? (response.Data.response.addressField).trim() : '',
                city : response.Data.response.cityField,
                state: response.Data.response.stateField,
                countryCode: response.Data.response.countryField,
                zip: response.Data.response.zipField
            }
            // let result = JSON.stringify(reqAddress)==JSON.stringify(resAddress)
            let isSuggestedAddress = !this.checkAddress(reqAddress,resAddress);
            if (isSuggestedAddress) {
                this.dialogRef = this._matDialog.open(VerifyAddressComponent, {
                    width: '550px',
                    maxWidth: '550px',
                    height: '456px',
                    panelClass: '',
                    data: {
                        suggestedAddress : resAddress,
                        userAddress : reqAddress,
                        InvalidAddress: false
                    },
                    disableClose: true
                });
                this.dialogRef.afterClosed()
                    .subscribe(response => {
                        if (response) {
                            //this._toastr.success("Shipping address updated!","Success");
                        }
                        this.router.navigate(['/', this.routPath]);
                    });
    
            }
            else
            {
                this.router.navigate(['/', this.routPath]);
            }
        })
    }

    checkAddress(req,res)
    {
        return req.address1==res.address1 && req.city == res.city && req.state == res.state && req.countryCode == res.countryCode && req.zip == res.zip ;

    }
    getItemPrice(product) {

        var item = _.filter(this._enrollSession.data.RetailOrderProductDetail, (item) => {
            return (item.ParentItemCode == product.ItemCode || !item.ParentItemCode) && (item.ItemCode == product.ItemCode || item.ItemCode == (product.ItemCode + '-P65'));
        });
        return item.length > 0 ? item[0].PriceEach : 0;
    }
    OpenDesktopCart()
    {
        this._sharedMethodService.isOpen = true;
    }
    validatePassword(password?: string) {
        if(password !== undefined && password !== null){
            const lowercaseRegex = new RegExp("(?=.*[a-z])"); // has at least one lower case letter
        if (lowercaseRegex.test(password)) {
            this.hasLower = true;
        } else {
            this.hasLower = false;
        }

        const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
        if (uppercaseRegex.test(password)) {
            this.hasUpper = true;
        } else {
            this.hasUpper = false;
        }

        const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
        if (numRegex.test(password)) {
            this.hasNum = true;
        } else {
            this.hasNum = false;
        }

        const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
        if (specialcharRegex.test(password)) {
            this.hasSpecial = true;
        } else {
            this.hasSpecial = false;
        }

        const passwordLength = password?.length;
        if (passwordLength >= 8) {
            this.hasLength = true;
        } else {
            this.hasLength = false;
        }  
        }
    }
    isLoginCartButton(){
        if(this._auth.isLoggedIn() && localStorage.getItem("isExistingAutoship") == "true"){
            return true;
        }
        else{
            return false;
        }
    }
}
