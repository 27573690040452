import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'app/shared/rest.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ultpack',
  templateUrl: './ultpack.component.html',
  styleUrls: ['./ultpack.component.scss']
})
export class UltpackComponent implements OnInit {
  productData : any;
  modelDesign : any;
  images : any;
  iframe : any;
  urlParams : any = {};
  webAliasData: any;
  products: any;
  BucketUrl = environment.BucketURl;
  constructor(
    private _restService: RestService,
    private _toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.callApi();
    this.urlParams = new URLSearchParams(window?.location?.search);
    this.webAliasData = this.urlParams.get('giftcard') || sessionStorage.getItem('giftCardName');
    this.products = this.urlParams.get('products');
    if (this.products) {
      window.localStorage.setItem('products', this.products);
      this.urlParams.delete('products');
      window.location.href = window.location.origin + window.location.pathname;
    }
    else {
      this.products = window.localStorage.getItem('products');
    }

  //   $(document).ready(function(){
  //     setTimeout(() => {
  //         this.images = document.images;
  //         this.images = Array.from(this.images);
  //         this.images.forEach((img) => {
  //             let ig = img.getAttribute("src").toString()
  //             if(ig.includes("flippingbook")){
  //                 img.setAttribute("alt", "image");
  //                 console.log("alt", "image");
  //             }
  //         });
  
  //         this.iframe = document.getElementsByTagName("iframe");
  //         this.iframe = Array.from(this.iframe);
  //         this.iframe.forEach((ifm) => {
  //             ifm.setAttribute("title", "title")
  //             console.log("iframe", "iframe");
  //         });
  
  //     },2500)
  // })
    // this.expansionPanel();
  }

callApi() {
  const request = {
    'EnrollmentType': '',
    'CustomerType': 22,
    'WebCategoryId': '',
    'isUpgradeCustomer': false,
    'IsSubscriptionOrder': false,
    'PriceType': ''
};
this._restService.getProducts(request).subscribe((res: any) => {
    if (res) {
        this.productData = res;
        // console.log(productData);
        // this.productData = JSON.parse(this.productData);
        // console.log(productData);
    } else {
        this._toastr.error('Error');
    }
}, error => {
    this._toastr.error(error.error.Message, 'Error');
});
}

openModel(id) {
  while (document.getElementById("add_after_me").nextElementSibling) {
    console.log(document.getElementById("add_after_me").nextElementSibling);
    document.getElementById("add_after_me").nextElementSibling.remove();
    console.log(document.getElementById("add_after_me").nextElementSibling);
  }

  if (id) {
    this.productData.Items.filter((item) => {
      if (item.ItemCode == id) {
        console.log("LongDetail2",item.LongDetail2);
        this.modelDesign = item.LongDetail2;
        return;
      }
    });

    document
      .getElementById("add_after_me")
      .insertAdjacentHTML("afterend", this.modelDesign);
  }

  // console.log(productData);
  document.getElementById("myModal").style.display = "block";
  var styles = {
    margin : "auto",
  };
  $(".image").css(styles);
}

displayNone()
{
  document.getElementById('myModal').style.display='none';
}

expansionPanel() {
  if (
    document.getElementById("opendropdown").classList.contains("v-open") &&
    document.getElementById("expansionIcon").classList.contains("expandicon")
  ) {
    console.log("in this panel first");
    document.getElementById("opendropdown").classList.remove("v-open");
    document.getElementById("opendropdown").classList.add("v-close");
    document
      .getElementById("expansionIcon")
      .classList.replace("expandicon", "notexpandicon");
  } else {
    document.getElementById("opendropdown").classList.remove("v-close");
    document.getElementById("opendropdown").classList.add("v-open");
    document.getElementById("expansionIcon").classList.add("expandicon");
  }
}

goToLink()
{
  var queryString = this.webAliasData && this.products ? ("?giftcard=" + this.webAliasData + "&products=" + this.products + "&site=ultpack") : (this.webAliasData ? "?giftcard=" + this.webAliasData + "&site=ultpack": this.products ? "?products=" + this.products + "&site=ultpack": "?site=ultpack" );
  var strLink =((window.location.hostname.search('wsicloud.net') > -1 || window.location.hostname.search('localhost') > -1 || window.location.hostname.search('127.0.0.1') > -1 ) ? "https://phpenroll.wsicloud.net/AllProducts" : "https://customer.puriumenrollment.com/AllProducts") + queryString ;
  // window.open(strLink);
  this.router.navigateByUrl('/AllProducts'+queryString);
}

}
