<div class="dialog-content-wrapper" id="AlwaysShip">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h1 class="pop-up-headline"> Always Ship </h1>
      <div class="border-line"></div>
      <div class="ship-text">
        If you choose ALWAYS SHIP - your monthly subscription order will Always Ship on your Subscription date.
      </div>
    </div>
  </div>
</div>


