import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { objectToParams } from 'app/constants';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  customerLoginForm: FormGroup;
  resetPasswordToken: any;
  public userInfo: any = {};
  public hasLower: boolean = false;
  public hasUpper: boolean = false;
  public hasNum: boolean = false;
  public hasSpecial: boolean = false;
  public hasLength: boolean = false;
  public showPassword: boolean = false;
  public showResetPassword: boolean = false;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public _enrollSession: EnrollSessionService,
    public router: Router, private _auth: AuthService,
    private _formBuilder: FormBuilder,
    public _toastr: ToastrService,
    public _restService: RestService,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.resetPasswordToken = params['resetpasswordtoken'];
    });
    this.customerLoginForm = this.createCustomerLoginForm();
  }
  ngOnInit(): void {
    this.ValidateLink().then(response => {
    }).catch(e => {
      console.log(e);
    });
  }
  createCustomerLoginForm(): FormGroup {
    return this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      reenterPassword: ['', [Validators.required]]
    },
      {
        validator: [this.MatchPassword('password', 'reenterPassword')]
      }
    )
  }

  MatchPassword(password: any, reenterPassword: any): any {
    return (customerInfoForm: FormGroup) => {
      const PasswordControl = customerInfoForm.controls[password];
      const ConfirmPasswordControl = customerInfoForm.controls[reenterPassword];
      if (!PasswordControl || !ConfirmPasswordControl) {
        return null;
      }
      if (PasswordControl.value && PasswordControl.value.length < 6) {

        return customerInfoForm.controls[password].setErrors({ passwordLength: true });
      }

      if (!ConfirmPasswordControl.value) {
        return customerInfoForm.controls[reenterPassword].setErrors({ required: true });
      }

      if (PasswordControl.value && PasswordControl.value !== ConfirmPasswordControl.value) {
        return customerInfoForm.controls[reenterPassword].setErrors({ passwordMismatch: true });
      }
      else {
        return customerInfoForm.controls[reenterPassword].setErrors(null);
      }
    };
  }
  reset() {
    if (this.userInfo.password == this.userInfo.reenterPassword && this.userInfo.password != '' && this.userInfo.password != undefined) {
      this.validatePassword(this.userInfo.password);
      if (this.hasLower == false || this.hasLength == false || this.hasUpper == false || this.hasNum == false || this.hasSpecial == false) {
        this._toastr.error('Please check your Password', 'error');
        return;
      }
      this.ValidateLink().then((result) => {
        var request = {
          CustomerId: result.Data.CustomerID,
          Password: this.userInfo.password
        }
        this._restService.ResetPassword(request).subscribe(result => {
          if (result) {
            // if(sessionStorage.getItem("siteType")=="ultlifestyle"){
            //   this.router.navigate(['/Home'])
            // }
            // else{
            this.router.navigate(['/Home'])
            // }
            //this._toastr.success('Password reset succesfully', "Success");
          }
        }, error => {
          this._toastr.error('Something Went Wrong', "Error");
          console.log(error);
        })
      }).catch(e => {
        console.log(e);
      });
    } else {
      this._toastr.error('Enter valid Password', "Error");
    }
  }
  validatePassword(password: string) {
    const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
    if (lowercaseRegex.test(password)) {
      this.hasLower = true;
    }
    else {
      this.hasLower = false;
    }

    const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
    if (uppercaseRegex.test(password)) {
      this.hasUpper = true;
    }
    else {
      this.hasUpper = false;
    }

    const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
    if (numRegex.test(password)) {
      this.hasNum = true;
    }
    else {
      this.hasNum = false;
    }

    const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
    if (specialcharRegex.test(password)) {
      this.hasSpecial = true;
    }
    else {
      this.hasSpecial = false;
    }
    const passwordLength = this.userInfo.password.length
    if (passwordLength >= 8) {
      this.hasLength = true;
    }
    else {
      this.hasLength = false;
    }
  }
  backtohome() {
    if (sessionStorage.getItem("siteType") == "ultlifestyle") {
      this.router.navigate(['/OrderReview'])
    }
    else {
      this.router.navigate(['/AllProducts'])
    }
  }
  ValidateLink(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      let req = "?token=" + encodeURIComponent(this.resetPasswordToken);
      this._restService.ValidateResetPasswordToken(req).subscribe(
        (res: any) => {
          if (res.Status == "Success") {
            //this._toastr.success('Token is valid', 'Success');
            resolve(res);
          } else {
            this._toastr.error('Error', res.ErrorMessage);
            reject();
          }
        },
        error => {
          this._toastr.error('Error', 'Oops Invalid Token');
          reject();
        }
      )
    })
    return promise;
  }
  togglePasswordShowHide() {
    this.showPassword = !this.showPassword;
  }
  toggleResetPasswordShowHide() {
    this.showResetPassword = !this.showResetPassword;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
