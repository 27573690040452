import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
// import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StepperModule } from '../stepper/stepper.module';
import { footerModule } from 'app/shared/footer/footer.module';
import { AppComponent } from "app/app.component";
import { NewsLetterSignupComponent } from "./newslettersignup.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PrivacyDocumentPopupDialogModule } from "./PrivacyDocumentDialog/privacy-document-dialog.module";

const routes: Routes = [
    {
        path: 'getnews',
        component: NewsLetterSignupComponent,
    }
  ];
@NgModule({
    declarations: [
        NewsLetterSignupComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgxChartsModule,
        MatExpansionModule,
        SummaryCartModule,
        FlexLayoutModule,
        MatDialogModule,
        StepperModule,
        footerModule,
        FormsModule,
        ReactiveFormsModule,
        PrivacyDocumentPopupDialogModule
        
    ], entryComponents: [
      
    ],
    providers: [],
    bootstrap: [AppComponent]
  })
export class NewsLetterSignupModule { }