<!-- ======================= PAGE CONTENT ====================================== -->
<app-header></app-header>
<div class="container mb-5">
    <!-- <h1 class="text-center">POLICIES &amp; PROCEDURES</h1> -->
    <img class="img-fluid" src="../../../../assets/img/ShippingBanner.png" />
    <p class="text-center">
    <h3 class="text-center">SHIPPING &amp; WILL CALL</h3>
    <!-- <p style="line-height: 22px;"><strong>Tis the season! Due to the holidays, you may experience longer shipping times.
            We apologize for the inconvenience and are working hard on getting your superfoods straight to your
            doorstep. Thank you for your patience.</strong>
    </p> -->
    <p style="line-height: 22px;"><strong>Special Note: </strong><em>I</em><em data-stringify-type="italic">f your
            tracking number hasn't moved, </em>Customer Care needs to wait at least 7 days before sending a replacement
        order.</p>
    <p>&nbsp;</p>
    <p style="line-height: 22px;"><strong>EXPRESS SHIPPING</strong></p>
    <p style="line-height: 22px;">We are delighted to share our <strong>NEW Express Shipping option</strong>. Express
        Shipping can take <strong>2-4 business days</strong>. Express Shipping is offered to all physical addresses in
        the US, including Alaska and Hawaii. <strong>NOTE:</strong> Express Shipping excludes P.O. Box Addresses and
        Smart Orders</p>
        <p style="line-height: 22px;">&nbsp;</p>
    <strong>
        FedEx Express&nbsp;cannot ship to&nbsp;P.O. Box addresses. If these orders are not
        updated to a residential address within 1 Business Day, the order will&nbsp;be&nbsp;shipped USPS Priority
        Mail.
    </strong>
    <p>&nbsp;</p>
    <p style="line-height: 22px;">&nbsp;</p>
    <p style="line-height: 22px;"><strong>FEDEX DELIVERY MANAGER APP</strong></p>
    <p style="line-height: 22px;">Most of Purium's orders are sent via FedEx. Download their Delivery Manager App to use
        these great features: </p>
    <ul style="line-height: 22px;">
        <li>Get tracking updates (via text message)</li>
        <li>Request package be held at “hold facility”</li>
        <li>Request a special delivery time (for a fee)</li>
        <li>Add delivery notes</li>
        <li>Set vacation time for all deliveries</li>
        <li>Request to hold your package for 14 days (this is the longest consecutive time period; must wait 6 days to
            do another hold)</li>
        <li>Use the FedEx “Virtual Assistant” and Customer Chat</li>
    </ul>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p style="line-height: 22px;"><strong>SHIPPING &amp; WILL CALL</strong></p>
    <p style="line-height: 22px;">All orders ship during regular business hours. When choosing your shipping method
        please be advised of the following:</p>
    <ul style="line-height: 22px;">
        <li>Will Call pick-up option available to California residents (read more below)</li>
        <li>All regularly placed orders are processed and shipped within 2-3 business days. </li>
        <li>Smart Orders are processed and shipped within 2-5 business days after the process date.</li>
        <li>
            <strong>USA PO Boxes and/or Military APO/FPO </strong>Addresses must select USPS.
        </li>
        <li>
            <strong>Customs</strong>: Purium is not responsible for any customs, conversion, or other fees associated
            with ordering from Purium. Transactions are always processed using US Dollars. Please check the Purium
            product label and ingredients with country's regulation standards. Your order could be rejected when passing
            through customs. Purium is not responsible for orders that are rejected by customs.
        </li>
        <li>Changes to your order after processing may cause delays within the shipping process.</li>
    </ul>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p style="line-height: 20px;"><strong><span style="font-size: 18px;">Continental USA, Hawaii, Alaska, US
                Territories</span>&nbsp;</strong></p>
    <p style="line-height: 22px;"><strong>Flat Rate Residential </strong>orders ship via FedEx or USPS based on Purium’s
        discretion. Delivery time frame is 1-7 business days from ship date and are made Tuesday thru Saturday.</p>
    <p style="line-height: 22px;"><strong>Flat Rate Commercial</strong> orders ship via FedEx to commercial business
        zoned addresses during open hours when someone is available to accept the package. Delivery timeframe is 1-7
        business days from ship date and are made Monday thru Friday. &nbsp;</p>
    <p style="line-height: 22px;">&nbsp;</p>
    <p style="line-height: 22px;"><strong>Shipping Prices Effective February 1, 2024</strong><br>
        Continental US - $11.95<br>
        HI, AK, US Territories or PO Boxes - $16.95<br>
        Canada - $19.95<br>
        Save $10 on S&H with orders of $250 or more!&nbsp;</p>
    <p style="line-height: 22px;">&nbsp;</p>
    <p style="line-height: 22px;"><span style="font-size: 18px;"><strong>Canada</strong></span></p>
    <p style="line-height: 22px;">
        <!-- <strong>Flat Rate</strong> orders ship via FedEx Consolidated Ground. Purium pays all
        customs clearance and service fees. Orders are shipped Mondays, Wednesdays and Fridays. Average shipping time is
        10–12 days; longer shipping times are experienced by those in remote areas of Canada. Tracking will not show on
        FedEx website until after 5 days. -->
        Canada orders are shipping International Economy
    </p>
    <ul>
        <li>Purium pays all customs clearance and service fees</li>
        <li>Purium ships orders out daily</li>
        <li>Average transit time is 4 - 7 days</li>
        <li>longer shipping times are experienced by those in remote areas of Canada</li>
        <li>Tracking will show on the FedEx website within a day</li>
    </ul>
    <p style="line-height:10px">&nbsp;</p>
    <p style="line-height: 22px;"><strong>WILL CALL (Please wear a mask)
    </strong><strong>*Only available to California residents*</strong>
    </p>
    <p style="line-height: 22px;">Wear a mask. <em>Ring the doorbell near the Will Call sign &amp; show your ID.
        </em><em>Park in visitor parking and ring the doorbell near the Will Call sign. </em></p>
    <p style="line-height: 22px;">Customer who purchased the order must show ID and be physically present to pick-up,
        unless other arrangements have been previously made through customer care.</p>
    <p style="line-height: 10px;">&nbsp;</p>
    <p style="line-height: 22px;"><strong>Note: Will Call order must be picked up within 10 days of this email. If not,
            the order will be cancelled and refunded.</strong></p>
    <p style="line-height: 10px;">&nbsp;</p>
    <p style="line-height: 22px;"><strong>Will Call Hours:</strong><br>Monday - Friday<br>8am - 5:30pm PT</p>
    <p style="line-height: 10px;">&nbsp;</p>
    <p style="line-height: 22px;"><strong>Will Call Address: </strong></p>
    <p style="line-height: 22px;">2610 Homestead Place<br>Rancho Dominguez, CA 90220</p>
    <p style="line-height: 22px;">&nbsp;</p>

    <h3 class="text-center">RETURNS&nbsp;&nbsp;</h3>

    <p style="text-align: center;"><strong>STANDARD 60-DAY MONEY BACK GUARANTEE </strong></p>
    <p style="text-align: left;">
        To return or exchange products for any reason, please send us back the package(s). Clearly mark RMA and your order # on the outside of the box and return all contents of your package within 60 days of receiving your order. Please include any specific details inside the package.
    </p>
    <p style="text-align: left;">If you are sending more than 1 box, please be sure to write the "RMA -Order Number" on each box.</p>
    <p style="text-align: left;">You must return each item that you are wishing to get a refund for.
         Even if the product has been completely used, container(s) must still be sent in order 
         for a refund to be issued. Returns must be received at our corporate office within 60 
         days of the date the original package was received by the customer. 
         Returns received past the 60-day window will be rejected unless prior approval for credit has
         been obtained.
    </p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>We highly recommend using a traceable shipping method</strong> when returning any
        Purium product. <strong>Purium Health Products is not responsible for lost or damaged items.</strong> If
        returning products by U.S. mail, please use registered, certified, or return receipt mail.</p>
    <p style="text-align: left;"><strong>It takes approximately 2 – 3 weeks for returns to be processed.</strong>
         We cannot refund a credit card other than the one used on the original order. Please let us know up front whether the credit card used on the order is still valid. If your credit card is no longer valid, your refund will come to you in the form of a check from Purium Health Products. At the time that your credit card is refunded, or a check is sent out, you will receive an email confirmation from the Returns Department.
    </p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;">Returns will be accepted at will call however they WILL NOT be processed on the spot.</p>
    <p style="text-align: left;">Please send all products you wish to return / refund to:</p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>US Returns</strong></p>
    <p style="text-align: left;">2610 Homestead Place <br> Rancho Dominguez, CA 90220</p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>Canada Returns</strong></p>
    <p style="text-align: left;"> 130 - 1959 152nd Street <br> STE 512 <br>Surrey, BC V4A 0C4</p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>Rewards Points Are Non-Refundable</strong></p>
    <p style="text-align: left;">We do not refund Rewards Points when a Customer or Brand Partner returns their
        product(s) that were purchased using their Rewards Points. While Customers and Brand Partners are able to accrue
        their Rewards Points and redeem when they want, the Rewards Points do expire after 3 months of no activity
        (earning or redeeming points). </p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>Refunds &nbsp;</strong></p>
    <p style="text-align: left;">*Purium reserves the right and has the responsibility to refuse any refund where there
        has been an abuse of our return policy. Purium reserves the right to charge a 20% re-stocking fee for products.
        The guarantee applies only towards products. There is no guarantee on Enrollment Packs or business tools. All
        refunds are handled at the discretion of Purium Health Products' management. Please note that standard refunds
        will not include any shipping fees incurred by the customer. Commissions paid out on returned items will be
        deducted from future commissions for the recipient of said commissions. In the event of a Company error or the
        receipt of damaged goods, please call Customer Service for more information.</p>
    <p style="line-height: 22px;">&nbsp;</p>

    <h3 style="text-align: center;">DISCLAIMERS</h3>

    <p style="text-align: left;">All testimonials appearing on this website represent the individual views and positions
        of their authors and not necessarily the views and positions of Purium Health Products. Consumers should not
        construe any information herein as medical advice or as a substitute for discussions with a prescribing
        practitioner or other qualified medical professional. These statements have not been evaluated by the U.S. Food
        and Drug Administration (FDA). The recommended use of Purium Health Products is not intended to diagnose, treat,
        cure or prevent any disease or medical condition. Always consult with a professional medical practitioner before
        taking any dietary supplement, especially if pregnant, nursing, taking prescription medications or under a
        doctor’s medical care.</p>
    <p style="text-align: left;">&nbsp;</p>
    <p style="text-align: left;"><strong>Income Disclaimer</strong></p>
    <p style="text-align: left;">Any representation or guarantee of earnings would be misleading. As with any business,
        your results may vary and will be based on your individual capacity, business experience, expertise, level of
        desire and amount of hours you put into your Purium business activities. Please check the Purium Compensation
        Plan for Average Earnings per Rank.</p>
    <p style="text-align: left;">
        <a [href]="BaseURL+'Authorized_Retailer_Terms__Conditions.pdf'"
            target="_blank" rel="noopener noreferrer"
            aria-label="Authorized Retailer Terms and Conditions - Opens in a new tab "><strong>Authorized Retailer
                Terms and Conditions</strong>
        </a>
    </p>
</div>

<!-- ============================================================ -->

<app-footercomponent></app-footercomponent>

<!-- Bootstrap Script -->
<script src="scrollingtext.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js">
</script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">