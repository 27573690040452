import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EnrollSessionService } from './enrollSession.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GetDiscountedItemsService {
  // items: any;
  // discountedItem: any;
  // IsCallCalculateOrder: boolean = false;
  EnrollSession: any;
  discountItem: any
  constructor(
    private _toastr: ToastrService,
    private _router: Router,
    private _http: HttpClient,
    private route: ActivatedRoute,
    private _restService: RestService,
    private _enrollSession: EnrollSessionService,
    private auth: AuthService) {
    // this.IsCallCalculateOrder = false;
    // this.items = ['11050-D', '11050-DD'];
    // this.discountedItem = { itemCode: '', qty: 0 };
    this.discountItem = { itemCode: '', qty: 0 };
    this._enrollSession.data = JSON.parse(localStorage.getItem('EnrollSession')) ? JSON.parse(localStorage.getItem('EnrollSession')) : {};
  }

  getDiscount(amount) {
    this.discountItem = { itemCode: '', qty: 0, Price: 0 };
    if(!this.auth.isLoggedIn() || this._enrollSession.data.LoggedInUserData.CustomerType == 18 || this._enrollSession.data.LoggedInUserData.CustomerType == 11){
      if (amount < 200 && amount >= 75) {
        this.discountItem.itemCode = '7800GC';
          this.discountItem.qty = 1;
          this.discountItem.Price = -50;
      }
    }
    return this.discountItem;
  }

}
