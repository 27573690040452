<div class="dialog-content-wrapper" id="BackUpOrder">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h1 class="pop-up-headline">  Backup Order </h1>
      <div class="border-line"></div>
      <div class="backup-text">
        If you choose BACKUP ORDER - If you place another order before your subscription date, your subscription will not be processed for that current month. (Your Subscription will remain Active for future months).
      </div>
    </div>
  </div>
</div>


