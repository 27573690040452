import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '../rest.service';

@Component({
  selector: 'subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.scss']
})
export class SubscribeDialogComponent implements OnInit {
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<SubscribeDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
  }

  ngOnInit(): void {

  }



  onSubscribe(SubscriptionStatus): void {
    // this._enrollSession.data.subscribeStatus = SubscriptionStatus;
    this.dialogRef.close(SubscriptionStatus);
  }

}
