import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from 'app/shared/header/header.component';



@NgModule({
  declarations: [

    StepperComponent
    // added header here to display on every page along with stepper.

  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    StepperComponent
  ]
})
export class StepperModule { }
