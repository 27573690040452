<!-- *************************** new code *************************************-->
<app-header></app-header>
<section class="normal-item checkout-div" *ngIf="pageLoad">
	<div fxLayout="column" id="prod-details" class="main-container"
		style="padding-bottom: 45px;background-color: white;margin-top: 30px;">
		<div class="color-b">
			<div class="container" fxLayout="column">
				<div fxLayout="row" fxLayoutAlign="space-between flex-start" id="backImg"> 
				<!-- image section -->
				<div class="hero_container" fxLayout="column" fxLayoutAlign="center start" id="backImg" fxFlex="30" fxFlex.md="50"
					fxLayout.xs="column" fxLayout.sm="column" fxHide.lt-md>
                    <div fxFlex=30 fxLayoutAlign="center center">
					  <img [src]="item?.SmallPicture" alt="pic" class="img-top-product">
				    </div>
					<div fxFlex=30 class="product-left" fxLayout="column" fxLayoutAlign="space-between center" fxHide.lt-sm>
						<div class="product-videos" fxLayoutAlign="center center" fxLayout="column"
							[innerHtml]="item?.leftSideLabel">
						</div>

						<div [innerHtml]="item?.DataBelowLabel" >
						</div>
					</div>
					
				</div>
				<!-- below img section start -->
				<div class="pd_first_container" fxLayout="column" fxLayout.lt-md="column" fxFlex="66" fxFlex.lt-md="100"
					fxLayoutAlign="space-between stretch" fxLayoutGap="35px">
					<!-- right side section start -->
					<div fxFlex=70 class="product-right">
						<!-- title -->
						<div class="product-title">
							<h2 class="item-detailDescription lowercase"
								style="font-family: 'Josefin Sans', sans-serif !important;font-weight: 500;margin-bottom: 2px;"
								[textContent]="item?.Description">
							</h2>
						</div>
						<!-- title end -->
						<!-- subtitle -->
						<div class="product-title">
							<h2 class="item-detailsubDescription lowercase"
								style="font-family: 'Josefin Sans', sans-serif !important;font-weight: 400;margin-top: 0;margin-bottom: 0;">
								{{subtitle}}
							</h2>
						</div>

						<!-- mob view img -->
						<div fxShow.lt-md fxHide.gt-sm>
							<img [src]="item?.SmallPicture" alt="pic" class="img-top-product">
						</div>

						<!-- reviews -->
						<div (click)="gotoReviews()" class="cursor-pointer">
							<p>{{reviews? ('★★★★★ ' + reviews + ' Reviews') : 'No reviews yet.' }}</p>
						</div>

						<!-- short detail -->
						<div class="item-detail-container" [ngStyle]="{'border-top': '3px solid '+ borderColor}">
							<span *ngIf="true" [innerHtml]="item?.ShortDetail" class="product_details_text"></span>
							<ng-template #elseDesciption>
								<div fxLayout="column">
									<span [innerHtml]="item?.ShortDetail" class="product_details_text"></span>
								</div>
							</ng-template>
						</div>
						<!-- short detail end -->


						<!-- if item has group members -->
						<div class="layout-row" fxLayout="row" *ngIf="item?.GroupMembers != null">
							<!-- item?.GroupMembers  && item?.GroupMembers?.length>0  -->
							<div class="w-100">
								<ng-container>
									<div class="m-0 p-20-0 custom-size">
										<p [textContent]="' flavor: '"></p>
										<mat-select readonly="true"
											(selectionChange)="checkOptions(item?.GroupMembers);" [(ngModel)]="value[0]"
											name="value.Option" (click)="openSelect($event)"
											class="md-no-underline-select"
											(ngInit)="value[0]=item?.GroupMembers[0].Item">
											<mat-option *ngFor="let option of item?.GroupMembers" [value]="option.Item"
												(click)="selectOption(item, item?.GroupMembers, value, false)">
												{{option.Item?.ItemDescription}} </mat-option>
										</mat-select>
									</div>
								</ng-container>
							</div>
						</div>
						<!-- group member button end -->

						<!-- button -->
						<div class="item-buy-btn-group" fxLayout="column">
							<br>
							<!-- subscribe -->
							<div class="one-time-order" fxLayout="row" fxLayout.lt-lg="column"
								fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-lg="space-between center"
								*ngIf="!_auth.isLoggedIn()">
								<!-- price -->
								<div fxFlex="33" fxFlex.lt-lg="100" class="price-column">
									<div class="item-detailprice font-weight-bolder">
										Loyal Customer: ${{item?.Price | number : '.2-2'}} <button #tooltip="matTooltip" class="fa fa-info-circle"
											style="font-size:16px;" matTooltip="Save 25% by ordering each month" matTooltipPosition="above"></button>
									</div>
								</div>
								<!-- BV -->
								<div fxFlex="17" fxFlex.lt-lg="100" class="price-column">
									<div class="item-detailprice font-weight-bolder"
									 *ngIf="_sharedMethodService.validCustomerType()">
										BV: {{item?.BusinessVolume}}
									</div>
								</div>
								<!-- texxt -->
								<div fxFlex="29" fxFlex.lt-lg="100" fxLayout="column">
									<h2>Retail: ${{item?.Other1Price | number : '.2-2'}}</h2>
								</div>
								<!-- button -->
								<div fxFlex="21" fxFlex.lt-lg="100">
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,2,sideNav)" *ngIf="!transformationPackMandatory && !subscriptionPack">
										<span translate="OneTimeOrder" class="font_family btn_color" >
											Add to Cart
										</span>
									</button>
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,1,sideNav)" *ngIf="transformationPackMandatory && !subscriptionPack">
										<span translate="OneTimeOrder" class="font_family btn_color" >
											Add to Cart
										</span>
									</button>
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,3,sideNav)" *ngIf="subscriptionPack && !transformationPackMandatory">
										<span translate="OneTimeOrder" class="font_family btn_color" >
											Add to Cart
										</span>
									</button>
								</div>
							</div>
							<br>
							<!-- logged in user button -->
							<div class="one-time-order" fxLayout="row" fxLayout.lt-lg="column"
								fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-xl="space-between center"
								*ngIf="_auth.isLoggedIn()">
								<!-- price -->
								<div fxFlex="33" fxFlex.lt-lg="100" class="price-column">
									<div class="item-detailprice font-weight-bolder" *ngFor="let userPrices of item.ItemPrices">
										<span *ngIf="userPrices.PriceTypeID === _enrollSession.data.priceType">Your Price: ${{userPrices.Price | number : '.2-2'}}</span>
									</div>
								</div>
								<!-- Bv -->
								<div fxFlex="17" fxFlex.lt-lg="100" class="price-column">
									<div class="item-detailprice font-weight-bolder"
									 *ngIf="_sharedMethodService.validCustomerType()">
										BV: {{item?.BusinessVolume}}
									</div>
								</div>
								<!-- texxt -->
								<div fxFlex="29" fxFlex.lt-lg="100" fxLayout="column">
									<h2 style="text-decoration: line-through">Retail: ${{item?.Other1Price | number : '.2-2'}}</h2>
								</div>
								<!-- button -->
								<div fxFlex="21" fxFlex.lt-lg="100">
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,2,sideNav)" *ngIf="!transformationPackMandatory && !subscriptionPack">
										<span translate="OneTimeOrder" class="font_family btn_color">
											Add to Cart
										</span>
									</button>
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,1,sideNav)" *ngIf="transformationPackMandatory && !subscriptionPack">
										<span translate="OneTimeOrder" class="font_family btn_color">
											Add to Cart
										</span>
									</button>
									<button class="light_btn w-100 " mat-dialog-close (click)="addToCart(item,1,3,sideNav)" *ngIf="subscriptionPack && !transformationPackMandatory">
										<span translate="OneTimeOrder" class="font_family btn_color">
											Add to Cart
										</span>
									</button>
								</div>
							</div>
							<!-- left side section for short detail 2 -->
							<br>
					        <div fxFlex="70" fxLayout="row" fxHide.lt-md>
						        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
							                        style="width:100%;height:auto;">
							        <div ngxSlickItem *ngFor="let slide of banners" class="slide">
								        <div style="width: 100%;height: auto;">
									        <img style="object-fit: contain;width: 100%;height: auto;" [src]="'https:'+slide"
										        alt="unable to load please try again">
								        </div>
							        </div>
						        </ngx-slick-carousel>
					        </div>
					        <!-- left side section end -->
						</div>

						<!-- mob view label -->
						<div class="product-videos d-mobile" fxLayoutAlign="center center" fxLayout="column"
							[innerHtml]="item?.leftSideLabel"
							style="border-radius: 30px;max-height: 320px;overflow-y: hidden;margin-top: 20px;width: auto;height: auto !important;" fxHide.gt-xs fxShow.lt-sm>
						</div>
						<div [innerHtml]="item?.DataBelowLabel" class="d-mobile" fxHide.gt-xs fxShow.lt-sm>
						</div>

						<!-- mob view carousel -->
						<div fxHide.gt-xs fxShow.lt-md>
							<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
								style="width:100%;height:auto;">
								<div ngxSlickItem *ngFor="let slide of banners" class="slide">
									<div style="width: 100%;height: auto;">
										<img style="object-fit: contain;width: 100%;height: auto;"
											[src]="'https:'+slide" alt="unable to load please try again">
									</div>
								</div>
							</ngx-slick-carousel>
						</div>

					</div>
					<!-- right side end -->
				</div>
			    </div>
				<!-- tabs start -->
				<div class="container responsive-tabs" fxHide.lt-md fxShow.gt-xs>
					
					<ul class="nav nav-tabs" role="tablist" style="background-color: #E6E6E6;border-radius: 30px;">
						<li class="nav-item" *ngIf="item?.LongDetail">
							<a id="tab-A" href="#pane-A" class="nav-link active poppins-reg" data-bs-toggle="tab" role="tab" tab-direction="left" (click) = clickOnTab(item)>Product Benefits</a>
						</li>
						<li class="nav-item" *ngIf="item?.LongDetail3">
							<a id="tab-B" href="#pane-B" class="nav-link poppins-reg" data-bs-toggle="tab" role="tab" tab-direction="middle" (click) = clickOnTab(item)>Label Info</a>
						</li>
						<li class="nav-item" *ngIf="item?.LongDetail4">
							<a id="tab-C" href="#pane-C" class="nav-link poppins-reg" data-bs-toggle="tab" role="tab" tab-direction="right" (click) = clickOnTab(item)>Learn More</a>
						</li>
					</ul>
				
					<div id="content" *ngIf="item?.LongDetail" class="tab-content" role="tablist">
						<div id="pane-A" class="card tab-pane fade show active border-0" role="tabpanel" aria-labelledby="tab-A">
							<div class="card-header border-0" role="tab" id="heading-A">
									<a class="collapsed tab-title" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
										<h5 class="mb-0 poppins-reg">Product Benefits</h5>
									</a> 
							</div>
							<div id="collapse-A" class="collapse" data-bs-parent="#content" role="tabpanel"
								aria-labelledby="heading-A">
								<div class="card-body js-reg" [innerHtml]="item?.LongDetail">
								</div>
							</div>
						</div>
				
						<div id="pane-B" *ngIf="item?.LongDetail3" class="card tab-pane fade border-0" role="tabpanel" aria-labelledby="tab-B">
							<div class="card-header border-0" role="tab" id="heading-B">
									<a class="collapsed tab-title" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="false"
										aria-controls="collapse-B">
										<h5 class="mb-0 poppins-reg">Label Info</h5>
									</a>
							</div>
							<div id="collapse-B" class="collapse" data-bs-parent="#content" role="tabpanel"
								aria-labelledby="heading-B">
								<div class="card-body js-reg" [innerHtml]="item?.LongDetail3">
								</div>
							</div>
						</div>
				
						<div *ngIf="item?.LongDetail4" id="pane-C" class="card tab-pane fade border-0" role="tabpanel" aria-labelledby="tab-C">
							<div class="card-header border-0" role="tab" id="heading-C">
									<a class="collapsed tab-title" data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
										
										<h5 class="mb-0 poppins-reg">Learn More</h5>
									</a>
							</div>
							<div id="collapse-C" class="collapse" data-bs-parent="#content" role="tabpanel"
								aria-labelledby="heading-C">
								<div class="card-body js-reg more-info-a" [innerHtml]="item?.LongDetail4">
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pd_second_container mt-10" fxHide.lt-md fxShow.gt-xs style="display: none;">
					<div fxLayout="column row" fxLayoutAlign="start start">
						<mat-tab-group class="product-detail-tabs" disableRipple="true"
							style="width: 100%;overflow-x: auto;font-size: 16px;" (selectedTabChange)="tabChanged()">
							<mat-tab *ngIf="item?.LongDetail" label='Benefits' fxLayoutAlign="start center" >
								<div [innerHtml]="item?.LongDetail" class="product-detail-tabs-content"
									style="margin-top: 20px;"></div>
							</mat-tab>
							<mat-tab *ngIf="item?.LongDetail3" label='Label Info' fxLayoutAlign="start center">
								<div [innerHtml]="item?.LongDetail3" class="product-detail-tabs-content"
									style="margin-top: 20px; padding: 20px;"></div>
							</mat-tab>
							<mat-tab *ngIf="item?.LongDetail4" label='Learn More' fxLayoutAlign="start center">
								<div [innerHtml]="item?.LongDetail4" class="product-detail-tabs-content anchors"
									style="margin-top: 20px; padding-left: 20px;"></div>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
				<!-- tabs end -->

				<!-- mob view displays -->
				<mat-accordion multi="false" fxHide.gt-xs fxShow.lt-md style="font-size: 18px;" class="onlyProduct">
					<mat-expansion-panel [expanded]=true (closed)="closeMobTab('benefits')" (opened)="openMobTab('benefits');tabChanged()" class="m-t-10">
						<mat-expansion-panel-header *ngIf="item?.LongDetail" label='Benefits'>
							<!-- headings -->
							Benefits
						</mat-expansion-panel-header>
						<!-- data -->
						<div [innerHtml]="item?.LongDetail" *ngIf="isPanelOpendFirst_1" class="m-t-10">

						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (closed)="closeMobTab('suggested');" (opened)="openMobTab('suggested');tabChanged()" class="m-t-10">
						<mat-expansion-panel-header *ngIf="item?.LongDetail3" label='Suggested'>
							<!-- headings -->
							Label Info
						</mat-expansion-panel-header>
						<!-- data -->
						<div [innerHtml]="item?.LongDetail3" class="m-t-10" *ngIf="isPanelOpendSecond">

						</div>
					</mat-expansion-panel>
					<mat-expansion-panel (closed)="closeMobTab('moreInfo')" (opened)="openMobTab('moreInfo');tabChanged()" class="m-t-10">
						<mat-expansion-panel-header *ngIf="item?.LongDetail4" label='moreInfo'>
							<!-- headings -->
							Learn More
						</mat-expansion-panel-header>
						<!-- data -->
						<div [innerHtml]="item?.LongDetail4" *ngIf="isPanelOpendThird">

						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>
		</div>
	</div>
	<div [innerHTML]="(!reviewList ? 'No reviews yet.' : (reviewList | safeHtml))" [ngClass]="{'no-review': !reviewList}" #ReviewElement></div>
	<ng-container>
		<div class="w-form" *ngIf="reviewSection">
			<form [formGroup]="customerReviewForm" class="customer-info-form custom">
				<div fxLayout="column">
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="100">
							<div>
								<label class="f-w-600">Name</label>
								<input type="text" class="form-field w-input" maxlength="256" name="First-Name"
									formControlName="Name" placeholder="Enter Your Name (public)*" id="First-Name"
									required>
							</div>
							<span class="help-block"
								*ngIf="!customerReviewForm.get('Name')?.valid&&customerReviewForm.get('Name')?.touched">
								<span *ngIf="customerReviewForm.get('Name').errors['required']">This field is
									required</span>
							</span>
						</div>
	
					</div>
					<div fxLayout="column" fxFlex="100">
						<div>
							<label class="f-w-600">Email</label>
							<input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
								formControlName="Email" placeholder="Enter Your email (private)*" id="Last-Name-2" required>
						</div>
						<span class="help-block"
							*ngIf="!customerReviewForm.get('Email')?.valid&&customerReviewForm.get('Email')?.touched">
							<span *ngIf="customerReviewForm.get('Email').errors['required']">This field is required</span>
							<span
							*ngIf="!customerReviewForm.get('Email').errors['required'] && !customerReviewForm.get('Email').valid">E-mail
							is invalid</span>
						</span>
					</div>
					<div fxLayout="column" fxFlex="100">
						<label class="f-w-600">Rating</label>
						<div>
							<ng-rating-bar [(value)]="rating" [ratingCount]="ratingCount" colorActive="#fbcd0a" colorDefault="#eee"></ng-rating-bar>
						</div>
	
					</div><br>
					<label class="f-w-600">Review Title</label>
					<input type="text" class="form-field w-input" maxlength="256" name="Phone"
						placeholder="Give Your review title*" id="ReviewTitle" formControlName="ReviewTitle" required>
	
					<span class="help-block"
						*ngIf="!customerReviewForm.get('ReviewTitle').valid&&customerReviewForm.get('ReviewTitle').touched">
						<span *ngIf="customerReviewForm.get('ReviewTitle').errors['required']">This field is required</span>
					</span>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="100">
							<textarea type="text" class="form-field w-input" name="WriteYourComment"
								placeholder="Write your comment here*" formControlName="WriteYourComment" rows="4" cols="50"
								required></textarea>
							<span class="help-block"
								*ngIf="!customerReviewForm.get('WriteYourComment').valid&&customerReviewForm.get('WriteYourComment').touched">
								<span *ngIf="customerReviewForm.get('WriteYourComment').errors['required']">This field is
									required</span>
							</span>
						</div>
					</div>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
							<label class="f-w-600">Picture / Video Review.</label>
							<div class="cursor-pointer w-form" (click)="uploadPicture.click()">
								<mat-icon class="f-s-60 picture">photo_camera</mat-icon>
								<input type="file" #uploadPicture formControlName="reviewPicture"
									class="form-field w-input uploadPicture" name="uploadPicture"
									(change)="addReviewPicture($event,'picture')" accept="image">
									<span *ngIf="addImage">Image added successfully</span>
							</div>
						</div>
					</div>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="30" fxFlex.xs="100" fxFlex.sm="100">
							<div class="cursor-pointer w-form" (click)="uploadVideo.click()">
								<mat-icon class="f-s-60 picture">videocam</mat-icon>
								<input type="file" #uploadVideo formControlName="reviewVideo"
									class="form-field w-input uploadPicture" name="uploadVideo"
									(change)="addReviewPicture($event,'video')" accept="video">
									<span *ngIf="addVideo">Video added successfully</span>
							</div>
						</div>
					</div>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="100">
							<input type="text" class="form-field w-input" name="YoutubeURL" placeholder="YouTube URL*"
								id="YoutubeURL">
						</div>
					</div>
					<div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
						<div fxLayout="column" fxFlex="100">
							<button class="form-field w-input review-button" (click)="submitReview()">Submit Review</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</ng-container>
	
	<app-footercomponent></app-footercomponent>
</section>
