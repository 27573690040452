<div class="dialog-content-wrapper" id="SubscriptionInfo">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <h4 class="pop-up-headline">  Your Subscription Date: {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 ? smartOrderDateForLifestyle : '--/--/----')}} </h4>
      <!-- <div class="border-line"></div> -->
      <div class="subscription-text">
        You have chosen to receive a monthly shipment of products. The shipping and billing information that you are using for your initial order will be the default method for your Subscription. You can update this at any time by logging into your Smart Office. Your credit card will only be charged today for today's order. Your credit card will NOT be charged today for your Monthly Subscription order. Your credit card will only be charged for your Monthly Subscription Order on the date you choose above.
      </div>
    </div>
  </div>
</div>


