<div id="NewsLettersSignup">
<!-- -------------------------    Customer Image Banner for Desktop   ------------------------------->
<ng-container *ngIf="(_router.url.includes('getnews')) && !_enrollSession.mobileViewMethod()">
    <div id="TopBanner" class="row" [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
        <div fxFlex="100" class="wp-block-heading has-text-align-center"
            id="welcome-banner" [ngClass]="{'p-20': (!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())}">
            <span fxFlex="15" *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
            <div fxFlex="79"  class="m-auto">
                <span class="welcome-text" *ngIf="(!_commonService.SubscribeClickCheck && _sharedMethodService.giftcardNameMethod())">Join {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                <span class="welcome-text" *ngIf="(_commonService.SubscribeClickCheck)">Welcome to {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <br *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())">
                    <!-- <br *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())"> -->
                <span *ngIf="(!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())" class="p-15">Join Purium’s Superfoods Community</span>
                <span *ngIf="((_commonService.SubscribeClickCheck) || _sharedMethodService.giftcardNameMethod())" class="welcome-text">Superfoods Community</span>
            </div>
        </div>
    </div>
</ng-container>

<!-- -------------------------    Customer Image Banner for Mobile   ------------------------------->
<ng-container *ngIf="_router.url.includes('getnews') && _enrollSession.mobileViewMethod()">
    <div id="TopBanner" [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
        <div class="wp-block-heading has-text-align-center"
            id="welcome-banner">
            <span *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())" id="userImagespan"><img src="{{_enrollSession.customerImage || '../../../assets/images/avatars/profile_icon.png'}}" onError="this.src='../../../assets/images/avatars/profile_icon.png';" class="userImage"></span>
            
            <div class="m-auto p-15">
                <span class="welcome-text" *ngIf="(!_commonService.SubscribeClickCheck && _sharedMethodService.giftcardNameMethod())">Join {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                <span class="welcome-text" *ngIf="(_commonService.SubscribeClickCheck)">Welcome to {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <br *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())">
                <span *ngIf="(!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())" class="p-15">Join Purium’s Superfoods Community</span>
                <span *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())" class="welcome-text">Superfoods Community</span>
            </div>
        </div>
    </div><br>
</ng-container>


<ng-container *ngIf="!_enrollSession.mobileViewMethod()">
    <div class="">
        <div [ngClass]="{'m-50':_commonService.SubscribeClickCheck,'divAlignment':_commonService.SubscribeClickCheck}">
          <div class="text-center" *ngIf="(_commonService.SubscribeClickCheck)">
            <p class="logo-below-text1" *ngIf="_commonService.EmailCheck">Hooray! You're already a
              valued<br> member of our community!</p>
            <p class="logo-below-text1" *ngIf="!_commonService.EmailCheck">
              <span>{{_enrollSession.data.RetailEnroll.webAlias?.split(' ')[0]}}</span> is excited to welcome you <br>to the
              Purium community.</p>
            <div>
              <p class="font-familyjose fs-35 red-c" *ngIf="_commonService.EmailCheck">This email is already registered.</p>
              <br><br>
              <p class="font-familyjose fs-35" *ngIf="!_commonService.EmailCheck">Thank you for signing up!</p>
              <p class="font-familyjose fs-35"> Save 25% on all $200+ orders  </p>
              <p class="font-familyjose fs-35">at <a class="black-c  fw-700 cursor-pointer" href="https://ishoppurium.com">ishoppurium.com</a></p>
              <br><br>
              <div class="text-center">
                <a class="main-btn shop-btn mt-40" (click)="ShopNow()">Shop Now</a>
              </div>
              <br><br><br>
              <div class="mt-30" *ngIf="_commonService.EmailCheck">
                  <p class="customerSupport">If you need support contact <span class="underline" (click)="RedirectToSupport()">customer care</span>. We are here for you. </p>
              </div>
          </div>
          </div>
            <div class="p-50 width-100"  *ngIf="!_commonService.SubscribeClickCheck">
              <div>
                <div class="padding-text">
                  <a class="">
                      <img src="../../../assets/images/logos/PuriumLogoOnNewsLetter.png" alt="" id="head_logo"
                          class="head_logoGreenLeaf">
                  </a>
              </div>
              <p class="logo-below-text" *ngIf="!_commonService.SubscribeClickCheck">Stay up-to-date with the <br> latest news on superfoods, <br> health and wellbeing.</p>
              </div>
                <div class="" *ngIf="!_commonService.SubscribeClickCheck">
                    <img class=""
                    src="../../../assets/images/newslettersignup/SpoonImageV2.png"
                    alt="">
                </div>
            </div>
            <div class="p-50 width-100" fxFlex="50"  *ngIf="!_commonService.SubscribeClickCheck">
                <p class="signupheading" *ngIf="!_commonService.SubscribeClickCheck">Sign up now!</p>
                <br *ngIf="(_commonService.SubscribeClickCheck) && !_commonService.EmailCheck">
                <br *ngIf="(_commonService.SubscribeClickCheck) && !_commonService.EmailCheck">
                <div class="border-box">
                    <div>
                        <div class="w-form">
                            <form [formGroup]="SignUpInfoForm" id="email-form1" name="email-form" class="signup-info-form custom">
                              <div fxLayout="column">
                                <div class="mt-20" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                  <div fxLayout="column" fxFlex="100">
                                    <div>
                                      <input type="text" class="form-field w-input" maxlength="30" name="First-Name"
                                        formControlName="FirstName" placeholder="First Name" id="First-Name" required>
                                    </div>
                                    <span class="help-block"
                                      *ngIf="!SignUpInfoForm.get('FirstName')?.valid&&SignUpInfoForm.get('FirstName')?.touched">
                                      <span *ngIf="SignUpInfoForm.get('FirstName').errors['required']">This field is required</span>
                                    </span>
                                  </div>
                                </div>
                                <div class="mt-20" fxLayout="row" fxFlex="100">
                                    <div fxLayout="column" fxFlex="100">
                                        <div>
                                          <input type="text" class="form-field  w-input" maxlength="30" name="Last-Name"
                                            formControlName="LastName" placeholder="Last Name" id="Last-Name-2" required>
                                        </div>
                                        <span class="help-block"
                                          *ngIf="!SignUpInfoForm.get('LastName')?.valid&&SignUpInfoForm.get('LastName')?.touched">
                                          <span *ngIf="SignUpInfoForm.get('LastName').errors['required']">This field is required</span>
                                        </span>
                                      </div>
                                  </div>
                                <div class="mt-20" fxLayout="row" fxFlex="100">
                                  <div fxLayout="column" fxFlex="100">
                                    <div>
                                      <input type="text" class="form-field w-input" maxlength="50" name="Email"
                                        formControlName="Email" placeholder="Email" id="emailAddress" required>
                                    </div>
                                    <span class="help-block"
                                        *ngIf="!SignUpInfoForm.get('Email').valid&&SignUpInfoForm.get('Email').touched&&!_auth.isLoggedIn()">
                                        <span
                                            *ngIf="!SignUpInfoForm.get('Email').errors['required'] && !SignUpInfoForm.get('Email').valid">E-mail
                                            is invalid</span>
                                        <span *ngIf="SignUpInfoForm.get('Email').errors['required']">This field is required</span>
                                    </span>
                                  </div>
                                </div>
                                <div class="mt-20" fxLayout="row" fxFlex="100">
                                    <div fxLayout="column" fxFlex="100">
                                      <div>
                                        <input type="text" class="form-field w-input input-content" maxlength="10" name="Phone"
                                          formControlName="Phone" placeholder="Phone Number (Optional)" id="phoneNumber">
                                      </div>
                                        <span class="help-block"
                                          *ngIf="!SignUpInfoForm.get('Phone').valid&&SignUpInfoForm.get('Phone').touched">
                                          <span *ngIf="!SignUpInfoForm.get('Phone').valid">Phone
                                            Number is invalid</span>
                                        </span>
                                        
                                    </div>
                                  </div>
                                  <div class="mt-20" fxLayout="row" fxFlex="100">
                                    <div fxLayout="column" fxFlex="100">
                                      <div>
                                        <input type="text" class="form-field w-input" maxlength="50" name="referralCode"
                                          formControlName="referralCode" placeholder="Referral Code" id="referralCode" [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
                                        required>
                                      </div>
                                        <span class="help-block"
                                          *ngIf="!SignUpInfoForm.get('referralCode').valid&&SignUpInfoForm.get('referralCode').touched">
                                          <span *ngIf="SignUpInfoForm.get('referralCode').errors['required']">This field is required</span>
                                        </span>
                                    </div>
                                  </div>
                              </div>
                            </form>
                        </div>
                        <div class="text-center">
                            <a class="main-btn subscribe-btn mt-40" (click)="SubscribeButton()"><div>Subscribe</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-30">
        <p class="privacyText" *ngIf="!_commonService.SubscribeClickCheck">By submitting your information, you are agreeing to <span class="underline" (click)="OpenToPrivacyPolicyPopup()">Purium's Privacy Policy.</span></p>
        <br>
    </div>
</ng-container>

<ng-container *ngIf="_enrollSession.mobileViewMethod()">
    <div class="">
        <div>
          <div class="text-center" *ngIf="(_commonService.SubscribeClickCheck)">
            <p class="logo-below-text1 fs-20" *ngIf="_commonService.EmailCheck">Hooray! You're already a
              valued<br> member of our community!</p>
            <p class="logo-below-text1 fs-20" *ngIf="!_commonService.EmailCheck">
              <span>{{_enrollSession.data.RetailEnroll.webAlias?.split(' ')[0]}}</span> is excited to welcome you <br>to the
              Purium community.
            </p>
            <div>
              <p class="font-familyjose fs-20 red-c" *ngIf="_commonService.EmailCheck">This email is already registered.</p>
              <br><br>
              <p class="font-familyjose fs-20" *ngIf="!_commonService.EmailCheck">Thank you for signing up!</p>
              <p class="font-familyjose fs-20"> Save 25% on all $200+ orders </p>
              <p class="font-familyjose fs-20">at <a class="black-c  fw-700 cursor-pointer"
                  href="https://ishoppurium.com">ishoppurium.com</a></p>
              <br><br>
              <div class="text-center">
                <a class="main-btn shop-btn mt-40" (click)="ShopNow()">Shop Now</a>
              </div>
              <br><br><br>
              <div class="mt-30" *ngIf="_commonService.EmailCheck">
                <p class="customerSupport">If you need support contact <span class="underline"
                    (click)="RedirectToSupport()">customer care</span>. We are here for you. </p>
              </div>
            </div>
          </div>
            <div class="p-10 width-100" *ngIf="!_commonService.SubscribeClickCheck">
                <p class="signupheading">Sign up now!</p>
                <div class="p-10 width-100">
                    <div class="">
                        <img class=""
                        src="../../../assets/images/newslettersignup/SpoonImageV2.png"
                        alt="">
                    </div>
                </div>
                <div class="border-box">
                    <div>
                        <div class="w-form">
                            <form [formGroup]="SignUpInfoForm" id="email-form1" name="email-form" class="signup-info-form custom">
                                <div fxLayout="column">
                                  <div class="mt-10" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
                                    <div fxLayout="column" fxFlex="100">
                                      <div>
                                        <input type="text" class="form-field w-input" maxlength="30" name="First-Name"
                                          formControlName="FirstName" placeholder="First Name" id="First-Name" required>
                                      </div>
                                      <span class="help-block"
                                        *ngIf="!SignUpInfoForm.get('FirstName')?.valid&&SignUpInfoForm.get('FirstName')?.touched">
                                        <span *ngIf="SignUpInfoForm.get('FirstName').errors['required']">This field is required</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="mt-10" fxLayout="row" fxFlex="100">
                                      <div fxLayout="column" fxFlex="100">
                                          <div>
                                            <input type="text" class="form-field  w-input" maxlength="30" name="Last-Name"
                                              formControlName="LastName" placeholder="Last Name" id="Last-Name-2" required>
                                          </div>
                                          <span class="help-block"
                                            *ngIf="!SignUpInfoForm.get('LastName')?.valid&&SignUpInfoForm.get('LastName')?.touched">
                                            <span *ngIf="SignUpInfoForm.get('LastName').errors['required']">This field is required</span>
                                          </span>
                                        </div>
                                    </div>
                                  <div class="mt-10" fxLayout="row" fxFlex="100">
                                    <div fxLayout="column" fxFlex="100">
                                      <div>
                                        <input type="text" class="form-field w-input" maxlength="50" name="Email"
                                          formControlName="Email" placeholder="Email" id="emailAddress" required>
                                      </div>
                                      <span class="help-block"
                                          *ngIf="!SignUpInfoForm.get('Email').valid&&SignUpInfoForm.get('Email').touched&&!_auth.isLoggedIn()">
                                          <span
                                              *ngIf="!SignUpInfoForm.get('Email').errors['required'] && !SignUpInfoForm.get('Email').valid">E-mail
                                              is invalid</span>
                                          <span *ngIf="SignUpInfoForm.get('Email').errors['required']">This field is required</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="mt-10" fxLayout="row" fxFlex="100">
                                      <div fxLayout="column" fxFlex="100">
                                        <div>
                                          <input type="text" class="form-field w-input" maxlength="10" name="Phone"
                                            formControlName="Phone" placeholder="Phone Number (Optional)" id="phoneNumber">
                                        </div>
                                          <span class="help-block"
                                            *ngIf="!SignUpInfoForm.get('Phone').valid&&SignUpInfoForm.get('Phone').touched">
                                            <span *ngIf="SignUpInfoForm.get('Phone').errors['required']">This field is required</span>
                                            <span *ngIf="!SignUpInfoForm.get('Phone').valid && !SignUpInfoForm.get('Phone').errors['required']">Phone
                                              Number is invalid</span>
                                          </span>
                                          
                                      </div>
                                    </div>
                                    <div class="mt-10" fxLayout="row" fxFlex="100">
                                      <div fxLayout="column" fxFlex="100">
                                        <div>
                                          <input type="text" class="form-field w-input" maxlength="50" name="referralCode"
                                            formControlName="referralCode" placeholder="Referral Code" id="referralCode" [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
                                            required>
                                        </div>
                                          <span class="help-block"
                                            *ngIf="!SignUpInfoForm.get('referralCode').valid&&SignUpInfoForm.get('referralCode').touched">
                                            <span *ngIf="SignUpInfoForm.get('referralCode').errors['required']">This field is required</span>
                                          </span>
                                      </div>
                                    </div>
                                </div>
                              </form>
                        </div>
                        <div class="text-center">
                            <a class="main-btn subscribe-btn mt-40" (click)="SubscribeButton()"><div>Subscribe</div></a>
                        </div>
                    </div>
                  </div>
            </div>
            <p class="logo-below-text" *ngIf="(!_commonService.SubscribeClickCheck)">Stay up-to-date with the <br> latest news on superfoods, <br> health and wellbeing.</p>
            <div class="mt-30 p-10">
                <p class="privacyText" *ngIf="!_commonService.SubscribeClickCheck">By submitting your information, you are agreeing to <span class="underline" (click)="OpenToPrivacyPolicyPopup()">Purium's Privacy Policy.</span></p>
                <br>
            </div> 
            <div class="text-center p-10" *ngIf="!_commonService.SubscribeClickCheck">
                <a class="">
                    <img src="../../../assets/images/newslettersignup/PuriumLogoFor Mobile.png" alt="" id="head_logo"
                        class="head_logoGreenLeaf m-tb-20">
                </a>
            </div>
            
        </div>
    </div>
</ng-container>

</div>


