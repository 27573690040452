import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URL, objectToParams } from 'app/constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnrollSessionService } from '../enrollSession.service';
import { AuthService } from '../auth.service';
import { RestService } from '../rest.service';
import { ToastrService } from 'ngx-toastr';
import { AutoshipConfirmation } from '../autoship-confirmaton/autoship-confirmaton.component';
import { ResetPasswordDialogComponent } from '../reset-password/reset-password.component';
import { SharedMethodService } from '../sharedMethod.service';
import { LoaderService } from 'app/loader.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  customerLoginForm: FormGroup;
  loading: boolean;
  currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  public validateShow: boolean = false;
  public appLoader: boolean = false;
  public show:boolean = false;
  public isReset:boolean = false;
  public forgotUsername: boolean = false;
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router, private _auth: AuthService,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog,
    private loaderService: LoaderService,
    public cookieService: CookieService,
    public _router: Router,
    public _activateRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService, public _sharedMethodService: SharedMethodService,) {
    this.currentRouterPath = this.router.url.split('?')[0];
    this.customerLoginForm = this.createCustomerLoginForm();
  }

  ngOnInit(): void {
    if (this.data?.header) {
      this.returingCustomer();
    }
  }

  createCustomerLoginForm(): FormGroup {
    return this._formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  newCustomer() {
    sessionStorage.setItem('newCustomer', 'true');
    this._enrollSession.data.RetailOrderSummary =  (sessionStorage.getItem("FromShareLinkCart") == "true"  ||  sessionStorage.getItem("FromCanteSite") == "true" || sessionStorage.getItem("FromBioMedic3Site") == "true") ? this._enrollSession.data.RetailOrderSummary : {};
    this.dialogRef.close(true);
  }

  returingCustomer() {
    this.userLoginDialog = true;
  }

  GetCustomersDetails(customerId) {
    const self = this;
    let req = "?CustomerID=" + (customerId ? customerId : 0);
    self._restService.GetCustomersDetails(req).subscribe(
      (res: any) => {
            this._enrollSession.data.priceType = res.customerDetail[0].PriceType;
            res.customerDetail[0].Email = res.customerDetail[0].Email ? res.customerDetail[0].Email.trim() : "";
        self._auth.sendUserData(JSON.stringify(res.customerDetail[0]));
        self._auth.getUserData();
        self._auth.sendDefaultCountryCode("1");
        if (res.customerDetail[0].CustomerType==20 || res.customerDetail[0].CustomerType==22 ) {
          this.getImage(res.customerDetail[0].EnrollerID);
        } else {
          this.getImage(res.customerDetail[0].CustomerID);
        }
        this.loaderService.isLoading.subscribe((v) => {
          this.loading = v;
        }, (err) => {
        });
        //self._toastr.success('Welcome ' + res.customerDetail[0].FirstName + res.customerDetail[0].LastName + '!', 'Success');
        this._enrollSession.data.LoggedInUserData = res.customerDetail[0];
        this._enrollSession.data.ReferredBy = '';
        this._enrollSession.data.RetailEnroll.webAlias = '';
        this._enrollSession.data.webAlias = '';
        localStorage.setItem('EnrollerData', JSON.stringify(res));
        var snapshot = this._activateRoute.snapshot;
        const params = { ...snapshot.queryParams };
        delete params.giftcard
        this.router.navigate([], { queryParams: params });
        if (this._sharedMethodService.validCustomerType()) {
          this.getBPProduct(544, res.customerDetail[0].CustomerType);
        }
        this.dialogRef.close(true);
      },
      error => {
        console.log(error, 'in error');
      }
    )
    self._restService.GetCustomerBilling(customerId).subscribe((res) => {
      self._auth.sendBillingInfo(JSON.stringify(res));
    }, error => {
      console.log("In error")
    })
  }

  SignIn() {
    if (this.customerLoginForm.invalid) {
      this.validateAllFormFields(this.customerLoginForm);
      return;
    }

    const request = objectToParams({
      client_id: 'exigo',
      grant_type: 'password',
      userName: this.userInfo.userName,
      password: this.userInfo.Password,
      scope: 'password'
    });
    if (localStorage.getItem('isLoggedIn') == 'true') {
      this._toastr.error('Error', 'User has already login');
      return;
    }
    this.appLoader = true;
    this._restService.Token(request).subscribe(
      {
        next: (result: any) => {
          if (result && result.access_token) {
            try {
              this.loginDetails = result;
              this._auth.sendToken(result);
              sessionStorage.setItem('SSOToken', result.SSOToken);
              this.GetCustomersDetails(this.loginDetails.CustomerID.toString());
              this.loaderService.isLoading.subscribe((v) => {
                this.loading = v;
              }, (err) => {
              });
              let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
              // if (FromShareLinkCart == "true" ) {
              //   this._enrollSession.data.RetailOrderSummary = {};
              // }
              this._enrollSession.data.RetailOrderSummary = {};
              //this._toastr.success('User login successfully', 'Success');
              this._enrollSession.data.RetailOrderSummary.customerInfoData = {};
              this._enrollSession.data.RetailOrderSummary.shippingFormData = {};
              this._enrollSession.data.RetailEnroll = {};
              this._enrollSession.data.isShippedToAnother = false;
              var access_token = JSON.parse(localStorage.getItem('authtoken'));
              sessionStorage.removeItem('temp2');
              sessionStorage.removeItem('temp1');
              this._enrollSession.data.IsLoggedIn = true;
              this.getImage(this.loginDetails.CustomerID);
              setTimeout(() => {
                this.cookieService.delete('access_token', '/');
                this.cookieService.delete('X-Auth', '/');
                this.cookieService.deleteAll('/');
                let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
                this._enrollSession.data.RetailOrderSummary =  FromShareLinkCart == "true" ? this._enrollSession.data.RetailOrderSummary : {};
                this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
                this._enrollSession.data.IsLoggedIn = false;
                this._enrollSession.data.LoggedInUserData = {};
                this._enrollSession.data.priceType = 0;
                sessionStorage.setItem('onUltLifestyleFlow', 'false');
                this._router.navigate(['/homepage']).then(() => {
                  if (window.localStorage && window.sessionStorage) {
                    window.localStorage.removeItem('user');
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                  }
                  window.location.reload();
                });
                  localStorage.setItem("isExistingAutoship", "false");
                alert("Your session has been expired!!")
              }, 1800000);
              this._sharedMethodService.getPointAccount(this.loginDetails.CustomerID);
            }
            catch (successEx) {
              this._toastr.error('Error', 'Oops Something Went Wrong');
              console.error('ex', successEx);
            }
          } else {
            this._toastr.error('Error', 'Authentication Failed');
            this.appLoader = false;
          }
        }, error: (err) => {
          this._toastr.error(err.error.error_description);
          // calling for checking password reset
          let req = "?email=" + (this.userInfo.userName);
          this.appLoader = false;
          this._restService.validateCustomer(req).subscribe((resy: any) => {
            if (resy) {
              let response = resy
              if (response.ResetPasswordRequired) {
                this.validateShow = true;
              } else {
                this.validateShow = false;
              }
            }
          })
        },
        complete: () => {
        }
      });
  }

  getImage(id)
  {
    this._enrollSession.customerImage =  API_URL +"/NonProfitEnrollment/GetCustomerImageData?customerId="+id || '../../../assets/images/avatars/profile_icon.png';
  }
  forgotPassword() {
    this.router.navigate(['/forgotPassword']);
  }

  resetPassword(type?:string) { 
    this.isReset = true;
    if (type=='username') {
      this.forgotUsername = true;
    }
    else
    {
      this.forgotUsername = false;
    }
  }
  
  toggleShowHide() {
    this.show = !this.show;
  }
  
  closeDialog() {
    this.dialogRef.close();
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  SendLink(type?:string)
  {
    if (this.userInfo.userName && (this.userInfo.userName != '')) {
      let req = '';
      if (type == 'username') {
        req = "?email=" + (this.userInfo.userName) + "&IsForgetUsername=true";
      }
      else
      {
        req = "?email=" + (this.userInfo.userName)+ "&IsForgetUsername=false";
      }
      this._restService.GetResetPasswordLink(req).subscribe(
        (res: any) => {
          //this._toastr.success('Link Sent Successfully', 'Success');
          this.dialogRef.close(true);
        },
        error => {
          this._toastr.error('Error', 'Oops Something Went Wrong');
        }
      )
    }
   
  }
  getBPProduct(webCategoryId, customertype) {
    var listData;

    const request = {
      'EnrollmentType': '',
      'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
      'WebCategoryId': webCategoryId,
      'isUpgradeCustomer': false,
      'IsSubscriptionOrder': false,
      'PriceType': ''
    };
    this._restService.getProducts(request).subscribe((res: any) => {
      listData = res.Items;
      listData.forEach((item) => {
        this._sharedMethodService.AllBPProducts.push(item);
      });
      sessionStorage.setItem('AllBPProducts',JSON.stringify(listData));
    });
  }
}
